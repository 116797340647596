import React, { useEffect, useState } from 'react';
import { Navbar, Container, Button, Row, Col } from 'react-bootstrap';
import classes from '../styles/Staking.module.css';
import Brand from '../logos/brand.svg';
import { ADMIN_WALLETS } from '../constants/value';
import AdminModal from './AdminModal';
import connectWalletProvider from 'lib/connectWallet';
import { Backdrop, Box, Fade, Typography, Modal } from '@mui/material';
import WalletProviders from './WalletProviders';
import ConnectWallet from 'lib/connectWallet';
import useWeb3 from 'hooks/useWeb3';
import walletConnectProvider from 'lib/connectWallet';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
  background: 'linear-gradient(255.52deg, #0294AB -173.55%, #0294AB 71.9%, rgba(60, 89, 190, 0.7) 117.57%)'
};
const connectionList = [
  {
    id: 1,
    name: 'Wallet Connect',
    logo: 'https://seeklogo.com/images/W/walletconnect-logo-EE83B50C97-seeklogo.com.png'
  },
  {
    id: 2,
    name: 'Metamask',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png'
  }
]
const Navmenu = (props) => {
    const [isConnect, setIsConnect] = useState(false);
    const [openAdmin, setOpenAdmin] = useState(false);
    const [openConnect, setOpenConnect] = useState(false);
    const connectWalletProvider = walletConnectProvider;
    const { setTrigger } = useWeb3();

    const onClickConnectWallet = () => {
        setOpenConnect(true);
    };

    useEffect(() => {
      if(connectWalletProvider.isConnect && connectWalletProvider.provider !== null) {
        setIsConnect(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectWalletProvider.isConnect, connectWalletProvider.isConnect])

    const disconnectWallet = async () => {
      const disconnect = await connectWalletProvider.disconnectWalletConnect();
      if(disconnect){
        setIsConnect(false);
        setTrigger();
      }
    };

    const openAdminModal = () => {
        setOpenAdmin(true);
    };

    const onClickConnect = async (id) => {
      if(id === 1){
        const connect = await connectWalletProvider.enableConnectWallet();
        if(connect !== null) {
          setIsConnect(true);
          setOpenConnect(false);
          setTrigger();
        }
      } else {
        const connect = await connectWalletProvider.enableMetamask();
        if(connect !== null) {
          setIsConnect(true);
          setOpenConnect(false);
          setTrigger();
        }
      }
    }

    return (
        <>
            <Navbar className={classes.customNavbar} expand={false}>
                <Container style={{ maxWidth: '1080px' }}>
                    <img src={Brand} alt="logo" style={{ width: 'auto', height: '50px', padding: '8px' }} />
                    <Row>
                        <Col>
                            <Button
                                placement="end"
                                onClick={isConnect ? disconnectWallet : onClickConnectWallet}
                                style={{
                                    background: 'transparent',
                                    borderColor: 'grey',
                                    borderRadius: 12,
                                    '&:hover': {
                                        background: 'red',
                                        borderColor: 'transparent'
                                    }
                                }}
                            >
                                {isConnect ? `Disconnect` : 'Connect'}
                            </Button>
                            {isConnect && ADMIN_WALLETS.includes(connectWalletProvider.account) && (
                                <Button
                                    placement="end"
                                    onClick={openAdminModal}
                                    style={{
                                        background: 'transparent',
                                        borderColor: 'grey',
                                        borderRadius: 12,
                                        '&:hover': {
                                            background: 'red',
                                            borderColor: 'transparent'
                                        },
                                        marginLeft: 10
                                    }}
                                >
                                    Settings
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Container>
                <AdminModal onRefreshCallback={props.onRefreshCallback} open={openAdmin} handleClose={() => setOpenAdmin(false)} />
            </Navbar>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openConnect}
              onClose={() => setOpenConnect(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openConnect}>
                <Box sx={style}>
                  <Typography id="transition-modal-title" variant="h4" component="h2">
                    Select Wallet
                  </Typography>
                  {
                    connectionList.map(x => (
                      <WalletProviders key={x.id} logo={x.logo} providerName={x.name} onClick={() => onClickConnect(x.id)} />
                    ))
                  }
                </Box>
              </Fade>
            </Modal>
        </>
    );
};

export default Navmenu;
