/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, IconButton, InputAdornment, Modal, OutlinedInput, Stack, TextField, Typography } from '@mui/material';
import { OnSale } from '../assets/svgs/OnSale';
import { Close } from '@mui/icons-material';
import ttd from '../assets/ttdLogo.svg';
import bnb from '../assets/bnbLogo.svg';
import moment from 'moment';
import useLoader from '../hooks/useLoader';
import Web3 from 'web3';
import { toCurrency, toSmallUnit, truncateWalletAddress } from '../utils/string.utils';
import nftAbi from '../abis/NFTToken.json';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { withStyles } from '@mui/styles';
import useSwal from '../hooks/useSwal';
import StakingAbi from '../abis/StakingAbi.json';
import { APPROVE_AMOUNT } from '../constants/value';
import classes from '../styles/Staking.module.css';
import { DEFAULT_IMAGE } from '../constants/common';
import PhaseChips from './PhaseChips';
import walletConnectProvider from 'lib/connectWallet';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 526,
    background: '#0E0A38',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
    maxHeight: 700,
    overflow: 'scroll'
};

function toSmallUnitAsString(valueAsString, decimalAsString) {
    const { toBN } = Web3.utils;

    const BN = toBN;

    const toWei = Web3.utils.toWei;
    const decimalAdjustment = 18 - parseInt(decimalAsString);
    var bnValue = BN(toWei(valueAsString, 'ether'));
    bnValue =
        decimalAdjustment >= 0
            ? bnValue.div(BN('10').pow(BN(decimalAdjustment.toString())))
            : bnValue.mul(BN('10').pow(BN((decimalAdjustment * -1).toString())));
    return bnValue;
}

const calDur = (eventTime) => {
  if(+eventTime !== 0){
    let now = new Date().getTime();
    let timeleft = +eventTime - now;
    let days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    let hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
  
    return {
      days,
      hours,
      minutes,
      seconds
    }
  }else{
    return {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00'
    }
  }
}

function Countdown({ eventTime, interval, phase }) {
    const [time, setTime] = useState({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    })
    const timerRef = useRef(0);
    const timeChange = useCallback(() => {
      setTime(calDur(eventTime))
    }, [eventTime])

    const title = {
        0: 'Prepare Time',
        1: 'Start Time',
        2: 'Stake Time',
        3: 'Ended Time'
    };

    useEffect(() => {
        timerRef.current = setInterval(timeChange, interval);

        return () => {
            clearInterval(timerRef.current);
        };
    }, [eventTime]);


    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <>
                <p className="presaleTitle">{title[phase]} in</p>
                <div
                    className="daysAndTime"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row'
                    }}
                >
                    <div
                        className="HMS"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row'
                        }}
                    >
                        <div className="time">
                            <p
                                className="number"
                                style={{
                                    fontSize: '25px',
                                    margin: '0 .7rem'
                                }}
                            >
                                {time.days}
                            </p>
                            <span>Days</span>
                        </div>
                        <p
                            className="number"
                            style={{
                                fontSize: '20px'
                            }}
                        >
                            :
                        </p>
                        <div className="time">
                            <p
                                className="number"
                                style={{
                                    fontSize: '25px',
                                    margin: '0 .7rem'
                                }}
                            >
                                {time.hours}
                            </p>
                            <span>Hours</span>
                        </div>
                        <p
                            className="number"
                            style={{
                                fontSize: '20px'
                            }}
                        >
                            :
                        </p>
                        <div className="time">
                            <p
                                className="number"
                                style={{
                                    fontSize: '25px',
                                    margin: '0 .7rem'
                                }}
                            >
                                {time.minutes}
                            </p>
                            <span>Minutes</span>
                        </div>
                        <p
                            className="number"
                            style={{
                                fontSize: '20px'
                            }}
                        >
                            :
                        </p>
                        <div className="time">
                            <p
                                className="number"
                                style={{
                                    fontSize: '25px',
                                    margin: '0 .7rem'
                                }}
                            >
                                {time.seconds}
                            </p>
                            <span>Seconds</span>
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
}


const DetailModal = ({
    open,
    handleClose,
    pair = 'NFT ~ DOOiT',
    day = '45',
    apy = '100%',
    minStake = '30000',
    maxStake = '50000',
    preparationTime = '0',
    stakingStartDate = '0',
    stakingEndDate = '0',
    baseContract = '',
    baseErcDecimal = '',
    ercSymbol,
    stakeContract,
    phase,
    rewardContract,
    baseToken,
    rewardToken,
    onErrorCallback = (data) => data,
    stakeDate,
    provider
}) => {
    const { startLoading, stopLoading } = useLoader();
    const { errror, info } = useSwal();

    const [balance, setBalance] = useState('0');
    const [userAddr, setUserAddr] = useState('0x');

    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [stake, setStake] = useState(0);
    const [amount, setAmount] = useState(0);
    const [rewardSymbol, setRewardSymbol] = useState(0);
    const [erc20Contract, setErc20Contract] = useState('');
    const [erc20Balance, setErc20Balance] = useState(0);
    const [erc20Decimal, setErc20Decimal] = useState(0);
    const [button, setButton] = useState('Unlock');
    const [endedTime, setEndedTime] = useState(0);
    const [endedTimeDisabled, setEndedTimeDisabled] = useState(true);
    const [onStake, setOnStake] = useState(false);
    const [onUnstake, setOnUnstake] = useState(false);
    const [startTime, setStartTime] = useState(0);
    const [unixEndedTime, setUnixEndedTime] = useState(0);
    const [humanStartDate, setHumanStartDate] = useState('');
    const [humanEndDate, setHumanEndDate] = useState('');
    const [phaseInText, setPhaseInText] = useState('Preparation');
    const [isUserStake, setIsUserStake] = useState(false);
    const savedAccount = localStorage.getItem('savedAccount');

    const initialModal = () => {
      init(savedAccount);
    }

    useEffect(() => {
      if(open){
        initialModal();
      }
    }, [open]);

    useEffect(() => {
      const _phases = +phase;
      if(_phases === 0){
        setPhaseInText('Preparation');
        return;
      }else if (_phases === 1){
        setPhaseInText('Open, You can stake!');
        return;
      }else if (_phases === 2){
        setPhaseInText('Locked, You can only claim your reward');
        return;
      }else {
        setPhaseInText('Closed, You can unstake or claim');
        return;
      }
    }, [phase])

    useEffect(() => {
      const humanStd = new Date(+startTime);
      const humanStdEnd = new Date(+endedTime);
      setHumanStartDate(`${humanStd.getMonth()} - ${humanStd.getDay()} - ${humanStd.getFullYear()}`);
      setHumanEndDate(`${humanStdEnd.getMonth()} - ${humanStdEnd.getDay()} - ${humanStdEnd.getFullYear()}`);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [null]);

    const getStakerForUser = async (address = "") => {
      console.log(address, '@addr')
      if(address === "" || address === null){
        return null;
      }else{
        const web3 = new Web3(
          provider || Web3.givenProvider || new Web3.providers.HttpProvider('https://rpc-bsc-main-01.3tokendigital.com/rpc')
        );
        const stakingContract = new web3.eth.Contract(StakingAbi, stakeContract);
        const getStaker = await stakingContract.methods.staker(address).call();
        console.log(getStaker, '@getStaker?');
        return getStaker;
      }
    }

    const init = async (account) => {
        setLoading(true);
        setInputValue(0);
        try {
            const web3 = new Web3(
                provider || Web3.givenProvider || new Web3.providers.HttpProvider('https://rpc-bsc-main-01.3tokendigital.com/rpc')
            );
            if (web3) {
                const stakingContract = new web3.eth.Contract(StakingAbi, stakeContract);
                const totalStake = 0;
                const _quote = rewardContract;
                const _baseERC = baseContract;
                const totalStaked = await stakingContract.methods.totalStaked().call();
                const _awarded = 0
                setStake(toSmallUnit(totalStaked, baseErcDecimal));
                if(+phase === 0){
                  setUnixEndedTime(preparationTime);
                }else if(+phase === 1){
                  setUnixEndedTime(stakingStartDate);
                }else if(+phase === 2){
                  setUnixEndedTime(stakeDate);
                }else{
                  setUnixEndedTime(stakingEndDate);
                }
                setEndedTime(stakingEndDate);
                setStartTime(stakingStartDate);
                if (stakingEndDate < Math.floor(new Date().getTime() / 1000)) {
                    setEndedTimeDisabled(true);
                }
                if (+_awarded <= 0) setOnUnstake(true);
                if (_baseERC) {
                    console.log(phase, '@phase?')
                    const ercContract = new web3.eth.Contract(nftAbi, _baseERC);
                    const _ercBalance = await ercContract.methods.balanceOf(account).call();
                    const _allowance = await ercContract.methods.allowance(account, stakeContract).call();
                    if(+phase === 2){
                      setButton('Claim')
                      setOnStake(true);
                    }else if (+_allowance > +_ercBalance) {
                      setButton('Stake');
                    } else {
                      setButton('Unlock');
                    }
                    setErc20Balance(_ercBalance);
                    setErc20Decimal(baseErcDecimal);
                    setErc20Contract(_baseERC);
                }

                if (_quote) {
                    const quoteContract = new web3.eth.Contract(nftAbi, _quote);
                    const _reward = await quoteContract.methods.symbol().call();
                    if (_reward) {
                        setRewardSymbol(_reward);
                    }
                }

                if (totalStake && baseErcDecimal) {
                    const stakers = toSmallUnit(+totalStake, +baseErcDecimal);
                    setStake(stakers);
                }
                console.log(account)
                const isUserStake = await getStakerForUser(account);
                if(isUserStake !== null){
                  if(+isUserStake !== 0){
                    setIsUserStake(true);
                  }else{
                    if(+phase !== 0 && +phase !== 1){
                      setIsUserStake(false);
                    }else{
                      setIsUserStake(true);
                    }
                  }
                }
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            if (err.message) {
                if (err.message.includes(`Returned values aren't valid`)) {
                    errror('Wrong Network', 'Please Switch your network to valid network');
                    return false;
                }
            }
            onErrorCallback(err.message);
            return false;
        }
    };

    const onClickUnstake = async () => {
        try {
            setOnStake(true);
            const _web3 = new Web3(provider || Web3.givenProvider || new Web3.providers.HttpProvider('https://rpc-bsc-main-01.3tokendigital.com/rpc'));
            const erc20Contracts = new _web3.eth.Contract(nftAbi, baseContract);
            const stakingContract = new _web3.eth.Contract(StakingAbi, stakeContract);
            if (button === 'Unlock') {
                const approval = await erc20Contracts.methods.approve(stakeContract, APPROVE_AMOUNT).send({ from: userAddr });
                if (approval) {
                    setOnStake(false);
                    setButton('Stake');
                }
            } else {
                if (+phase !== 2) {
                    const stakerAmount = await stakingContract.methods.staker(userAddr).call();
                    if (stakerAmount) {
                        const unstake = await stakingContract.methods.unstake(stakerAmount).send({ from: userAddr });
                        if (unstake) {
                            setOnStake(false);
                            info('Success', 'You have been unstake ' + ercSymbol, 'success');
                            initialModal();
                        }
                    }
                } else {
                    setOnStake(false);
                    info('Ooops', 'Staking can be used only in phase 1');
                }
            }
        } catch (err) {
            setOnStake(false);
            console.log(err, '@errorClickUnstaked');
            onErrorCallback(err.message);
            errror('Some error occured', err.message);
        }
    };

    const onClickClaim = async () => {
      try {
        const _web3 = new Web3(
            provider || Web3.givenProvider || new Web3.providers.HttpProvider('https://rpc-bsc-main-01.3tokendigital.com/rpc')
        );
        const stakingContract = new _web3.eth.Contract(StakingAbi, stakeContract);
        const claim = await stakingContract.methods.claim().send({ from: userAddr });
        if (claim) {
            initialModal();
            info('Success', 'You have been claim ' + rewardSymbol, 'success');
        }
      } catch (err) {
          console.log(err, '@errOnClickClaim');
          errror('Some error occured', err.message);
      }
    }

    const getBalance = async () => {
        try {
            const getAccount = await localStorage.getItem('savedAccount');
            if(getAccount !== null){
              console.log(getAccount, '@getAccount')
              const web3 = new Web3(provider || Web3.givenProvider || new Web3.providers.HttpProvider('https://rpc-bsc-main-01.3tokendigital.com/rpc'));
  
              const ercContract = new web3.eth.Contract(nftAbi, baseContract); //erc contract
              const ercBalance = await ercContract.methods.balanceOf(getAccount).call();
  
              setUserAddr(getAccount);
  
              if (ercBalance) {
                  setBalance(toSmallUnit(ercBalance, baseErcDecimal));
                  init(getAccount);
              } else {
                  setBalance('0');
                  init(getAccount);
              }
            }
        } catch (err) {
            onErrorCallback(err.message);
            console.log(err, '@error getBalance');
        }
    };

    const onClickMax = async () => {
        const unit = toSmallUnit(erc20Balance, erc20Decimal) * 0.99999;
        setAmount(unit);
    };

    const inputChangeHandler = (event) => {
        const _num = event.target.value;
        setAmount(_num);
    };

    const onStakeClick = async () => {
        try {
            setOnStake(true);
            setButton('Loading...');
            const _web3 = new Web3(provider || Web3.givenProvider || new Web3.providers.HttpProvider('https://rpc-bsc-main-01.3tokendigital.com/rpc'));
            const erc20Contracts = new _web3.eth.Contract(nftAbi, erc20Contract);
            const stakingContract = new _web3.eth.Contract(StakingAbi, stakeContract);
            if (button === 'Unlock') {
                startLoading('Unlocking ...');
                const accounts = await _web3.eth.getAccounts();
                const approval = await erc20Contracts.methods.approve(stakeContract, APPROVE_AMOUNT).send({ from: accounts[0] });
                if (approval) {
                    stopLoading();
                    setOnStake(false);
                    setButton('Stake');
                }
            } else {
                startLoading('Staking ...');
                const weiAmount = toSmallUnitAsString(amount.toString(), erc20Decimal.toString()).toString();
                if (+phase === 1) {
                    const stake = await stakingContract.methods.stake(weiAmount).send({ from: userAddr });
                    if (stake) {
                        stopLoading();
                        setButton('stake');
                        setOnStake(false);
                        info('Success', 'You have been stake ' + ercSymbol, 'success');
                        initialModal();
                    }
                } else {
                    stopLoading();
                    setOnStake(false);
                    setButton('Stake');
                    info('Ooops', 'Staking can be used only in phase 1');
                }
            }
        } catch (err) {
            console.log(err);
            onErrorCallback(err.message);
            stopLoading();
            setOnStake(false);
            // errror('Some error occured', err.message);
        }
    };

    useEffect(() => {
        if (phase === 0 && endedTimeDisabled) {
            setButton('Upcoming');
        }
    }, [phase, endedTimeDisabled]);

    useEffect(() => {
        getBalance();
    }, [savedAccount]);

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid container direction={'column'} spacing={2}>
                    <Grid item width={'100%'}>
                        <Stack
                            justifyContent={'space-between'}
                            direction={'row'}
                            width="100%"
                            alignItems={'center'}
                            justifyItems={'center'}
                        >
                            <PhaseChips phases={+phase} endedTimeDisabled={endedTimeDisabled} />
                            <IconButton onClick={handleClose}>
                                <Close sx={{ color: '#FFFF' }} color="inherit" />
                            </IconButton>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <div
                            className="d-flex"
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '0 auto',
                                height: '100px'
                            }}
                        >
                            <img
                                src={baseToken ? baseToken : bnb}
                                alt="ttd"
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    marginLeft: '33%',
                                    top: '10%',
                                    width: '20%',
                                    zIndex: 10
                                }}
                            />
                            <img
                                src={rewardToken ? rewardToken : bnb}
                                alt="bnb"
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    marginRight: '33%',
                                    top: '10%',
                                    width: '20%'
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item alignSelf={'center'}>
                        <Typography variant="h4" component={'div'}>
                            Stake {pair}
                        </Typography>
                    </Grid>
                    <Grid item alignSelf={'center'}>
                        {day} Day
                    </Grid>
                    {/* apy */}
                    <Grid item width={'100%'}>
                        <Stack
                            justifyContent={'space-between'}
                            direction={'row'}
                            width="100%"
                            alignItems={'center'}
                            justifyItems={'center'}
                        >
                            <Typography fontWeight={'bold'}>APY</Typography>
                            <Typography fontWeight={'bold'}>{apy}</Typography>
                        </Stack>
                    </Grid>
                    {/* min stake */}
                    <Grid item width={'100%'}>
                        <Stack
                            justifyContent={'space-between'}
                            direction={'row'}
                            width="100%"
                            alignItems={'center'}
                            justifyItems={'center'}
                        >
                            <Typography fontWeight={'bold'}>Min Stake</Typography>
                            <Typography fontWeight={'bold'}>{toCurrency(+toSmallUnit(minStake, baseErcDecimal))}</Typography>
                        </Stack>
                    </Grid>
                    {/* max stake */}
                    <Grid item width={'100%'}>
                        <Stack
                            justifyContent={'space-between'}
                            direction={'row'}
                            width="100%"
                            alignItems={'center'}
                            justifyItems={'center'}
                        >
                            <Typography fontWeight={'bold'}>Max Stake</Typography>
                            <Typography fontWeight={'bold'}>{toCurrency(+toSmallUnit(maxStake, baseErcDecimal))}</Typography>
                        </Stack>
                    </Grid>
                    {/* tvl */}
                    <Grid item width={'100%'}>
                        <Stack
                            justifyContent={'space-between'}
                            direction={'row'}
                            width="100%"
                            alignItems={'center'}
                            justifyItems={'center'}
                        >
                            <Typography fontWeight={'bold'}>TVL</Typography>
                            <Typography fontWeight={'bold'}>{stake}</Typography>
                        </Stack>
                    </Grid>
                    {/* staking start date */}
                    <Grid item width={'100%'}>
                        <Stack
                            justifyContent={'space-between'}
                            direction={'row'}
                            width="100%"
                            alignItems={'center'}
                            justifyItems={'center'}
                        >
                            <Typography fontWeight={'bold'}>Staking Date Start</Typography>
                            <Typography fontWeight={'bold'}>{humanStartDate}</Typography>
                        </Stack>
                    </Grid>
                    {/* staking end date */}
                    <Grid item width={'100%'}>
                        <Stack
                            justifyContent={'space-between'}
                            direction={'row'}
                            width="100%"
                            alignItems={'center'}
                            justifyItems={'center'}
                        >
                            <Typography fontWeight={'bold'}>Staking End Start</Typography>
                            <Typography fontWeight={'bold'}>{humanEndDate}</Typography>
                        </Stack>
                    </Grid>
                    {/* others */}
                    <Grid item width={'100%'} m={1}>
                        <Divider variant="middle" />
                    </Grid>
                    {/* date */}
                    <Grid item width={'100%'}>
                        <Stack direction={'column'} spacing={2}>
                            <Countdown eventTime={phase === 0 ? preparationTime : phase === 1 ? startTime : phase === 2 ? stakeDate :  unixEndedTime} interval={1000} phase={+phase} />
                        </Stack>
                    </Grid>
                    <Grid item width={'100%'}>
                        <Divider variant="fullWidth" />
                    </Grid>
                    <Grid item p={2} width={'100%'}>
                        <Stack direction={'column'} spacing={2}>
                            <Typography>
                                Your Balance : {balance} {ercSymbol}
                            </Typography>
                            <Typography color="red">
                                Current Phase : {phase} - {phaseInText}
                            </Typography>
                            <Stack
                                direction={'row'}
                                width={'100%'}
                                justifyContent={'space-between'}
                                justifyItems={'center'}
                                alignItems={'center'}
                                sx={{ border: `1px solid #FFF`, borderRadius: 2, p: 1, backgroundColor: 'rgba(255,255,255,0.5)' }}
                            >
                                <img src={baseToken ? baseToken : bnb} alt="ttd" width={30} height={30} />
                                <Typography alignItems={'center'} textAlign={'center'}>
                                    {userAddr}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item p={2} width={'100%'}>
                        <Stack direction={'column'} spacing={2}>
                            <div className={classes.inputGroup}>
                                <InputGroup type="number" min="0" step="1" onChange={inputChangeHandler} value={inputValue}>
                                    <img src={baseToken ? baseToken : bnb} alt="logo" style={{ width: '40px', padding: '8px' }} />
                                    <FormControl
                                        placeholder={amount}
                                        value={loading ? 'Loading ...' : amount}
                                        className={classes.inputField}
                                        type="number"
                                        onChange={(e) => {
                                            let _i = e.target.value;
                                            if (+_i > toSmallUnit(erc20Balance, erc20Decimal) * 0.99999) {
                                                setAmount(toSmallUnit(erc20Balance, erc20Decimal) * 0.99999);
                                            } else {
                                                setAmount(_i);
                                            }
                                        }}
                                        disabled={+phase === 3}
                                    />
                                    <Button
                                        variant="secondary"
                                        style={{
                                            background:
                                                'linear-gradient(255.52deg, #0294AB -173.55%, #0294AB 71.9%, rgba(60, 89, 190, 0.7) 117.57%)',
                                            borderColor: 'transparent',
                                            width: 90
                                        }}
                                        disabled={onStake}
                                        onClick={onClickMax}
                                    >
                                        Max
                                    </Button>
                                </InputGroup>
                            </div>
                        </Stack>
                    </Grid>
                    {
                      isUserStake ? (
                        <Grid item p={2}>
                            <Button
                                variant="secondary"
                                style={{
                                    background: 'linear-gradient(255.52deg, #0294AB -173.55%, #0294AB 71.9%, rgba(60, 89, 190, 0.7) 117.57%)',
                                    borderColor: 'transparent'
                                }}
                                disabled={+phase === 0 && endedTimeDisabled && onUnstake}
                                className="w-100"
                                onClick={() => {
                                  if(button === 'Unstake'){
                                    onClickUnstake();
                                  }else if(button === 'Claim'){
                                    onClickClaim();
                                  }else{
                                    onStakeClick();
                                  }
                                }}
                            >
                                {+phase === 0 && endedTimeDisabled ? 'Upcoming' : button}
                            </Button>
                        </Grid>
                      ) : <Typography textAlign={'center'} color="red" fontWeight={"bold"}>You're not stakers</Typography>
                    }
                </Grid>
            </Box>
        </Modal>
    );
};

DetailModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

export default DetailModal;
