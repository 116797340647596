import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';

const fileTypes = ['JPG', 'PNG', 'GIF'];

function ImagePicker({ onPickerCallback, id, isExist = false }) {
    const [isDisabled, setIsDisabled] = useState(false);

    const handleChange = (file) => {
        onPickerCallback(file, id);
    };

    useEffect(() => {
        if (isExist) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [isExist]);

    return <FileUploader disabled={isDisabled} handleChange={handleChange} name="file" types={fileTypes} />;
}

export default ImagePicker;
