import React from 'react';
import Web3 from 'web3';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Web3Provider } from './hooks/useWeb3';
import { Web3ReactProvider } from '@web3-react/core';
import { SwalProvider } from './hooks/useSwal';
import AppRoutes from './Routes';
import { FirebaseProvider } from './hooks/useFirebase';
import { LoaderProvider } from './hooks/useLoader';

const App = () => {
    return (
        <Web3Provider>
            <FirebaseProvider>
                <SwalProvider>
                    <LoaderProvider>
                        <AppRoutes />
                    </LoaderProvider>
                </SwalProvider>
            </FirebaseProvider>
        </Web3Provider>
    );
};

export default App;
