/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import '../styles/wallet.provider.css';

const WalletProviders = ({ logo, providerName, onClick, key }) => (
          <div
            style={{
                cursor: 'pointer',
                border: `1px solid #0C6EFD`,
                borderRadius: '12px',
                margin: 5
            }}
            className="main"
            onClick={onClick}
          >
            <Row className="justify-content-md-center" style={{ padding: 5, margin: 5 }}>
                <Col>
                    <Image width={50} height={50} src={logo} />
                </Col>
                <Col xs={8} style={{ alignItems: 'center' }}>
                    <p
                        style={{
                            marginTop: 10
                        }}
                    >
                        {providerName}
                    </p>
                </Col>
            </Row>
        </div>
)

export default WalletProviders
