/*eslint no-dupe-keys: "off"*/
import React, { useEffect, useMemo, useState } from 'react';
// import { useWeb3React } from '@web3-react/core'
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const ErrMsg = withReactContent(Swal);

export const Web3Context = React.createContext(null);

let web3Modal;

const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            rpc: {
                56: 'https://rpc-bsc-main-01.3tokendigital.com/rpc',
                56: 'https://bsc-dataseed1.ninicoin.io',
                56: 'https://bsc-dataseed1.defibit.io',
                97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
                97: 'https://data-seed-prebsc-2-s2.binance.org:8545/',
                97: 'https://data-seed-prebsc-1-s3.binance.org:8545/',
                97: 'https://data-seed-prebsc-2-s3.binance.org:8545/'
            },
            chainId: 56,
            network: 'binance',
            bridge: 'https://bridge.walletconnect.org',
            qrcodeModalOptions: {
                mobileLinks: ['trust', 'metamask', 'neftipedia']
            }
        }
    }
};

if (typeof window !== 'undefined') {
    web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions,
        theme: {
            background: 'rgb(39, 49, 56)',
            main: 'rgb(199, 199, 199)',
            secondary: 'rgb(136, 136, 136)',
            border: 'rgba(195, 195, 195, 0.14)',
            hover: 'rgb(16, 26, 32)'
        }
    });
}

export const Web3Provider = ({ children }) => {
    const [isConnected, setIsConnected] = React.useState(false);
    const [provider, setProvider] = React.useState();
    const [address, setAddress] = React.useState('');
    const [triggerConnect, setTriggerConnect] = useState(false);

    const connectWeb3Modal = async () => {
        try {
            console.log('onConnectToWeb3');
            const _provider = await web3Modal.connect();
            if (_provider) {
                const web3 = new Web3(_provider);
                const account = await web3.eth.getAccounts();

                setAddress(account[0]);
                setProvider(_provider);
                setIsConnected(true);

                await localStorage.setItem('account', account[0]);

                console.log('done!');
                const obj = {
                    _account: account[0],
                    _provider
                };

                return obj;
            }
        } catch (e) {
            if (typeof e !== 'undefined') {
                if (e.message) {
                    if (e.message.includes("Returned values aren't valid")) {
                        ErrMsg.fire({
                            title: 'Wrong Network',
                            html: <i>You need to switch your network on your NEFTiWALLET or another wallet to BSC MainNet network.</i>,
                            icon: 'error'
                        });
                    } else {
                        ErrMsg.fire({
                            title: 'Some error occured while connect to wallet',
                            html: <i>{e.message}</i>,
                            icon: 'error'
                        });
                    }
                } else {
                    if (e) {
                        if (e.includes('Modal closed by user')) {
                            ErrMsg.fire({
                                title: 'Connection Reverted',
                                html: <i>{e || 'You can ask our cs'}</i>,
                                icon: 'info'
                            });
                        } else {
                            ErrMsg.fire({
                                title: 'Some error occured while connect to wallet',
                                html: <i>{e.message || e || 'You can ask our cs'}</i>,
                                icon: 'error'
                            });
                        }
                    } else {
                        ErrMsg.fire({
                            title: 'Some error occured while connect to wallet',
                            html: <i>{e.message || e || 'You can ask our cs'}</i>,
                            icon: 'error'
                        });
                    }
                }
            }
        }
    };
    const disconnectToInjectedProviders = async () => {
        try {
            await web3Modal.clearCachedProvider();
            setIsConnected(false);
            setProvider(null);
            setAddress('');
            window.localStorage.removeItem('walletconnect');
            return true;
        } catch (err) {
            console.log(err.message);
            return false;
        }
    };

    useEffect(() => {
        if (typeof provider !== 'undefined' && provider !== null) {
            if (provider.on) {
                onProviderCallback();
            }
        }
    }, [provider, disconnectToInjectedProviders]);

    const onProviderCallback = () => {
        const handleAccountsChanged = (accountCallback = []) => {
            console.log(accountCallback, '@account');
            setAddress(accountCallback[0]);
        };

        //https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
        const handleChainChanged = (_hexChainId = '') => {
            console.log('@on chain changed');
            window.location.reload();
        };

        provider.on('accountsChanged', handleAccountsChanged);
        provider.on('chainChanged', handleChainChanged);
    };

    const init = async () => {
        const _connect = await connectWeb3Modal();
        if (_connect) {
            setAddress(_connect._account);
            setProvider(_connect._provider);
        }
    };

    const cleanup = async () => {
        await disconnectToInjectedProviders();
    };

    // useEffect(() => {
    //     init();
    //     return () => {
    //         cleanup();
    //     };
    // }, []);

    const setTrigger = () => {
      setTriggerConnect(!triggerConnect);
    }

    const values = useMemo(
        () => ({
            isConnected,
            disconnectToInjectedProviders,
            connectWeb3Modal,
            address,
            provider,
            setTrigger,
            triggerConnect,
        }),
        [isConnected, address, provider, triggerConnect]
    );

    return <Web3Context.Provider value={values}>{children}</Web3Context.Provider>;
};

export default function useWeb3() {
    const context = React.useContext(Web3Context);
    if (context === undefined) {
        throw new Error('useWeb3 hook must be with a useWeb3Provider');
    }
    return context;
}
