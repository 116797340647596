import WalletConnectProvider from "@walletconnect/web3-provider";
import Swal from "sweetalert2";
import { WCCHAIN } from "utils/web3.chains";
import Web3 from "web3";

class ConnectWallet {
  account = "";
  provider = null;
  isConnect = false;
  constructor() {
    this.account = "";
    this.provider = null;
    this.isConnect = false;
  }

  async enableConnectWallet() {
    try {
      const provider = new WalletConnectProvider({
        rpc: WCCHAIN,
        qrcode: true,
        bridge: 'https://bridge.walletconnect.org'
      });
      await provider.enable();
      const web3 = new Web3(provider);
      const account = await web3.eth.getAccounts();
  
      this.account = account[0];
      this.provider = provider;
      this.isConnect = true;
  
      localStorage.setItem("savedAccount", account[0]);
      localStorage.setItem("connected", true);
  
      // on connect subscriber
      provider.onConnect(() => {console.log("Successfully connect to wallet")});
  
      // Subscribe to accounts change
      provider.on("accountsChanged", (accounts) => {
        this.account = accounts[0];
        localStorage.setItem("savedAccount",accounts[0]);
        localStorage.setItem("connected", true);
      });
  
      // Subscribe to chainId change
      provider.on("chainChanged", (chainId) => {
        if(chainId !== 56) {
          Swal.fire({
            title: "Wrong Network",
            text: "Please switch your network to BSC Smart Chain",
            icon: 'error',
            confirmButtonColor: '#FFF',
            background: 'linear-gradient(255.52deg, #CF0010 -173.55%, #E53C35 71.9%, #F2484B 117.57%)'
          });
        }
      });
  
      // Subscribe to session disconnection
      provider.on("disconnect", (code, reason) => {
        this.isConnect = false;
        this.provider = null;
        localStorage.setItem("savedAccount", "");
        localStorage.setItem("connected", false);
      });
      return provider;
    } catch (err) {
      console.error(err);
      return null
    }
  }

  async enableMetamask() {
    try {
      if(typeof window?.ethereum !== 'undefined') {
        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
        console.log(account, '@account?', window.web3);

        this.account = account[0];
        this.provider = window.ethereum;
        this.isConnect = true;

        localStorage.setItem("savedAccount", account[0]);
        localStorage.setItem("connected", true);

        window.ethereum.on("accountsChanged", (accounts) => {
          this.account = accounts[0];
          localStorage.setItem("savedAccount",accounts[0]);
          localStorage.setItem("connected", true);
        });
    
        // Subscribe to chainId change
        window.ethereum.on("chainChanged", (chainId) => {
          if(chainId !== 56) {
            Swal.fire({
              title: "Wrong Network",
              text: "Please switch your network to BSC Smart Chain",
              icon: 'error',
              confirmButtonColor: '#FFF',
              background: 'linear-gradient(255.52deg, #CF0010 -173.55%, #E53C35 71.9%, #F2484B 117.57%)'
            });
          }
        });
    
        // Subscribe to session disconnection
        window.ethereum.on("disconnect", (code, reason) => {
          this.isConnect = false;
          this.provider = null;
          localStorage.setItem("savedAccount", "");
          localStorage.setItem("connected", false);
        });
        return window.ethereum;
      }else{
        Swal.fire({
          title: "No Metamask defined on your browser",
          text: "Please intall metamask on your browser",
          icon: 'error',
          confirmButtonColor: '#FFF',
          background: 'linear-gradient(255.52deg, #CF0010 -173.55%, #E53C35 71.9%, #F2484B 117.57%)'
        });
        return null
      }
    } catch (error) {
      Swal.fire({
        title: "Something wentWrong",
        text: error.message || "Unknown error occured",
        icon: 'error',
        confirmButtonColor: '#FFF',
        background: 'linear-gradient(255.52deg, #CF0010 -173.55%, #E53C35 71.9%, #F2484B 117.57%)'
      });
      return null
    }
  }

  async disconnectWalletConnect() {
    try {
      if(this.provider !== null ){
        if(this.provider.disconnect) {
          await this.provider.disconnect();
          localStorage.setItem("savedAccount", "");
          localStorage.setItem("connected", false);
          this.isConnect = false;
          this.provider = null;
          this.account = "";
          return true;
        } else {
          console.log(this.provider, '@prov?')
          localStorage.setItem("savedAccount", "");
          localStorage.setItem("connected", false);
          this.isConnect = false;
          this.provider = null;
          this.account = "";
          return true;
        }
      }
      window.location.reload();
    } catch (err) {
      throw new Error(err);
    }
  }

  async checkConnection() {
    if(this.isConnect && this.account !== ""){
      return true;
    }else{
      return false;
    }
  }
}
const walletConnectProvider = new ConnectWallet();
export default walletConnectProvider;