import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Modal, Stack, Typography } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 100,
    background: 'rgb(8, 45, 95) 100%',
    borderRadius: 4,
    boxShadow: 24,
    p: 4
};

const LoadingModal = ({ open, handleClose }) => {
    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style} display="flex" justifyContent="center" alignItems="center">
                <Stack direction={'column'} spacing={2} alignItems="center">
                    <CircularProgress />
                    <Typography>Loading ...</Typography>
                </Stack>
            </Box>
        </Modal>
    );
};

LoadingModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

export default LoadingModal;
