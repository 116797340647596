/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton, Modal, Stack, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close, ContentCopy, ContentPaste } from '@mui/icons-material';
import useFormFields from '../hooks/useForm';
import { Button } from 'react-bootstrap';
import useFirebase from '../hooks/useFirebase';
import useLoader from '../hooks/useLoader';
import StakingDatePicker from './Datepicker';
import ImagePicker from './ImagePicker';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 600,
    background: 'rgb(8, 45, 95) 100%',
    borderRadius: 4,
    boxShadow: 24,
    maxHeight: 700,
    overflow: 'scroll',
    p: 4
};

const CustomTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'white'
        },
        '& label': {
            color: 'white'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white'
            },
            '&:hover fieldset': {
                borderColor: 'white'
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white'
            }
        }
    }
})(TextField);

const AddStakingModal = ({
    open,
    handleClose,
    defValue = {
        APY: '',
        baseErcDecimal: '',
        base_contract: '',
        day: '',
        ercSymbol: '',
        maxAmount: '',
        maxParticipant: '',
        minAmount: '',
        name: '',
        precision: '',
        quoteDecimals: '',
        rewardContract: '',
        stakeContract: '',
        status: 'publish',
        totalRewards: '',
        startDate: new Date().getTime(),
        endedDate: new Date().getTime(),
        preparationDate: new Date().getTime(),
        stakeDate: new Date().getTime(),
        baseToken: '',
        rewardToken: ''
    },
    isEdit = false
}) => {
    const {
        fields,
        handleFieldsChange: handleFields,
        set,
        reset
    } = useFormFields({
        APY: '',
        baseErcDecimal: '',
        base_contract: '',
        day: '',
        ercSymbol: '',
        maxAmount: '',
        maxParticipant: '',
        minAmount: '',
        name: '',
        precision: '',
        quoteDecimals: '',
        rewardContract: '',
        stakeContract: '',
        status: 'publish',
        totalRewards: '',
        startDate: new Date().getTime(),
        endedDate: new Date().getTime(),
        preparationDate: new Date().getTime(),
        baseToken: '',
        rewardToken: ''
    });
    const [errorForm, setErrorForm] = useState({
        APY: false,
        baseErcDecimal: false,
        base_contract: false,
        day: false,
        ercSymbol: false,
        maxAmount: false,
        maxParticipant: false,
        minAmount: false,
        name: false,
        precision: false,
        quoteDecimals: false,
        rewardContract: false,
        stakeContract: false,
        totalRewards: false
    });
    const [errorText, setErrorText] = useState({
        APY: 'APY is only number',
        baseErcDecimal: 'Base ERC Decimals is only number',
        base_contract: 'Base Contract is only start with 0x',
        day: 'Day is only number',
        ercSymbol: 'Erc Symbol canot be empty',
        maxAmount: 'Max Amount is only number',
        maxParticipant: 'Max Participant is only number',
        minAmount: 'Min Amount is only number',
        name: 'Name canot be empty',
        precision: 'Precision is only number',
        quoteDecimals: 'Quote Decimals is only number',
        rewardContract: 'Reward Contract is only start with 0x',
        stakeContract: 'Stake Contract is only start with 0x',
        totalRewards: 'Total Rewards is only number'
    });
    const { addStakingData, updateStakingData } = useFirebase();
    const { startLoading, stopLoading } = useLoader();
    const saveData = async (e) => {
        if (!isEdit) {
            try {
                const isOk = isFieldsEmpty();
                if (!isOk) {
                    startLoading();
                    const add = await addStakingData(
                        fields.APY + '%',
                        fields.baseErcDecimal,
                        fields.base_contract,
                        fields.day,
                        fields.ercSymbol,
                        fields.maxAmount,
                        fields.maxParticipant,
                        fields.minAmount,
                        fields.name,
                        fields.precision,
                        fields.quoteDecimals,
                        fields.rewardContract,
                        fields.stakeContract,
                        fields.status,
                        fields.totalRewards,
                        fields.startDate,
                        fields.endedDate,
                        fields.preparationDate,
                        fields.stakeDate,
                        fields.baseToken,
                        fields.rewardToken
                    );
                    if (typeof add === 'string') {
                        stopLoading();
                        handleClose(true);
                    }
                }
            } catch (err) {
                stopLoading();
                console.log(err, '@err');
            }
        } else {
            const isOk = isFieldsEmpty();
            console.log(isOk, '@isok?');
            if (!isOk) {
                startLoading();
                const update = await updateStakingData(
                    fields.APY + '%',
                    fields.baseErcDecimal,
                    fields.base_contract,
                    fields.day,
                    fields.ercSymbol,
                    fields.maxAmount,
                    fields.maxParticipant,
                    fields.minAmount,
                    fields.name,
                    fields.precision,
                    fields.quoteDecimals,
                    fields.rewardContract,
                    fields.stakeContract,
                    fields.status,
                    fields.totalRewards,
                    defValue.id,
                    fields.startDate,
                    fields.endedDate,
                    fields.preparationDate,
                    fields.stakeDate,
                    fields.baseToken,
                    fields.rewardToken
                );
                if (typeof update === 'string') {
                    stopLoading();
                    handleClose(true);
                }
            }
        }
    };

    useEffect(() => {
        console.log(defValue);
        if (isEdit) {
            set(defValue);
        } else {
            reset();
        }
    }, [defValue]);

    const onPaste = async (e) => {
        const clip = await navigator.clipboard.readText();
        set({
            ...fields,
            [e]: clip
        });
    };

    const changeFieldWithReplacement = (e, replacement) => {
        const val = e.target.value;
        const id = e.target.id;
        const isError = isFormError(id, val);
        setErrorForm({
            ...errorForm,
            [e.target.id]: isError
        });
        if (val) {
            set({
                ...fields,
                [id]: val.replace(replacement, '')
            });
        }
    };

    const isFieldsEmpty = () => {
        return Object.values(fields).every((x) => x === '');
    };

    const isFormError = (id, val) => {
        switch (id) {
            case 'APY' ||
                'baseErcDecimals' ||
                'totalRewards' ||
                'quoteDecimals' ||
                'precision' ||
                'minAmount' ||
                'maxAmount' ||
                'maxParticipant' ||
                'day':
                return isNaN(val);
            case 'base_contract' || 'rewardContract' || 'stakeContract':
                return !String(val).startsWith('0x');
            case 'name' || 'ercSymbol' || 'startDate' || 'endedDate' || 'stakeDate' || 'preparationDate':
                return String(val) === '' || val === 0;
            default:
                return false;
        }
    };

    const handleFieldsChange = (e) => {
        const isError = isFormError(e.target.id, e.target.value);
        setErrorForm({
            ...errorForm,
            [e.target.id]: isError
        });
        handleFields(e);
    };

    const onDatePickerChange = (id, value) => {
        set({
            ...fields,
            [id]: value
        });
    };

    const convertFileToBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const onChangeImagePicker = async (file, id) => {
        try {
            startLoading();
            const base64 = await convertFileToBase64(file);
            if (base64) {
                set({
                    ...fields,
                    [id]: base64
                });
                stopLoading();
            }
        } catch (err) {
            stopLoading();
            console.log(err, '@errorUploadImage');
        }
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid container direction={'column'} spacing={2}>
                    <Grid item>
                        <Stack direction="row" width="100%" justifyContent="space-between" alignItems={'center'} justifyItems={'center'}>
                            <Typography textAlign={'center'}>Add Staking Data</Typography>
                            <IconButton onClick={handleClose}>
                                <Close sx={{ color: '#FFFF' }} />
                            </IconButton>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction={'column'} spacing={1}>
                            <Typography>Base Token</Typography>
                            <ImagePicker id={'baseToken'} onPickerCallback={onChangeImagePicker} isExist={isEdit} />
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction={'column'} spacing={1}>
                            <Typography>Reward Token</Typography>
                            <ImagePicker id={'rewardToken'} onPickerCallback={onChangeImagePicker} isExist={isEdit} />
                        </Stack>
                    </Grid>
                    <Grid item width={'100%'}>
                        <CustomTextField
                            value={fields.APY}
                            onChange={(e) => changeFieldWithReplacement(e, '%')}
                            id="APY"
                            error={errorForm.APY}
                            label="Apy"
                            helperText={errorForm.APY ? errorText.APY : 'Annual Percentage Yield Cannot be empty'}
                            sx={{ width: '100%' }}
                            inputProps={{
                                style: { color: '#FFF' }
                            }}
                            FormHelperTextProps={{
                                style: { color: errorForm.APY ? 'red' : '#FFF', fontWeight: errorForm.APY ? 'bold' : undefined }
                            }}
                            InputProps={{
                                startAdornment: <Typography sx={{ color: 'white' }}>%</Typography>
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            value={fields.baseErcDecimal}
                            onChange={handleFieldsChange}
                            id="baseErcDecimal"
                            label="Base Erc Decimal"
                            helperText={errorForm.baseErcDecimal ? errorText.baseErcDecimal : 'Base Erc Cannot be empty'}
                            sx={{ width: '100%' }}
                            FormHelperTextProps={{
                                style: {
                                    color: errorForm.baseErcDecimal ? 'red' : '#FFF',
                                    fontWeight: errorForm.baseErcDecimal ? 'bold' : undefined
                                }
                            }}
                            inputProps={{
                                style: { color: '#FFF' }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            value={fields.base_contract}
                            onChange={handleFieldsChange}
                            id="base_contract"
                            label="Base Contract"
                            helperText={errorForm.base_contract ? errorText.base_contract : 'Base Contract Cannot be empty'}
                            sx={{ width: '100%' }}
                            FormHelperTextProps={{
                                style: {
                                    color: errorForm.base_contract ? 'red' : '#FFF',
                                    fontWeight: errorForm.base_contract ? 'bold' : undefined
                                }
                            }}
                            inputProps={{
                                style: { color: '#FFF' }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={(e) => onPaste('base_contract', e)}>
                                        <ContentPaste sx={{ color: 'white' }} />
                                    </IconButton>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            value={fields.day}
                            onChange={handleFieldsChange}
                            id="day"
                            label="Day"
                            helperText={errorForm.day ? errorText.day : 'Day Cannot be empty'}
                            sx={{ width: '100%' }}
                            FormHelperTextProps={{
                                style: { color: errorForm.day ? 'red' : '#FFF', fontWeight: errorForm.day ? 'bold' : undefined }
                            }}
                            inputProps={{
                                style: { color: '#FFF' }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            value={fields.ercSymbol}
                            onChange={handleFieldsChange}
                            id="ercSymbol"
                            label="Erc Symbol"
                            helperText={errorForm.ercSymbol ? errorText.ercSymbol : 'Erc Symbol Cannot be empty'}
                            sx={{ width: '100%' }}
                            FormHelperTextProps={{
                                style: { color: errorForm.ercSymbol ? 'red' : '#FFF', fontWeight: errorForm.ercSymbol ? 'bold' : undefined }
                            }}
                            inputProps={{
                                style: { color: '#FFF' }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            value={fields.maxAmount}
                            onChange={handleFieldsChange}
                            id="maxAmount"
                            label="Max Amount"
                            helperText={errorForm.maxAmount ? errorText.maxAmount : 'Please fill Max Amount field with normal unit'}
                            sx={{ width: '100%' }}
                            FormHelperTextProps={{
                                style: { color: errorForm.maxAmount ? 'red' : '#FFF', fontWeight: errorForm.maxAmount ? 'bold' : undefined }
                            }}
                            inputProps={{
                                style: { color: '#FFF' }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            value={fields.maxParticipant}
                            onChange={handleFieldsChange}
                            id="maxParticipant"
                            label="Max Participant"
                            helperText={errorForm.maxParticipant ? errorText.maxParticipant : 'Max Participant Cannot be empty'}
                            sx={{ width: '100%' }}
                            FormHelperTextProps={{
                                style: {
                                    color: errorForm.maxParticipant ? 'red' : '#FFF',
                                    fontWeight: errorForm.maxParticipant ? 'bold' : undefined
                                }
                            }}
                            inputProps={{
                                style: { color: '#FFF' }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            value={fields.minAmount}
                            onChange={handleFieldsChange}
                            id="minAmount"
                            label="Min Amount"
                            helperText={errorForm.minAmount ? errorText.minAmount : 'Please fill Min Amount field with normal unit'}
                            sx={{ width: '100%' }}
                            FormHelperTextProps={{
                                style: { color: errorForm.minAmount ? 'red' : '#FFF', fontWeight: errorForm.minAmount ? 'bold' : undefined }
                            }}
                            inputProps={{
                                style: { color: '#FFF' }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            value={fields.name}
                            onChange={handleFieldsChange}
                            id="name"
                            label="Name"
                            helperText={errorForm.name ? errorText.name : 'Name Cannot be empty'}
                            sx={{ width: '100%' }}
                            FormHelperTextProps={{
                                style: { color: errorForm.name ? 'red' : '#FFF', fontWeight: errorForm.name ? 'bold' : undefined }
                            }}
                            inputProps={{
                                style: { color: '#FFF' }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            value={fields.precision}
                            onChange={handleFieldsChange}
                            id="precision"
                            label="Precision"
                            helperText={errorForm.precision ? errorText.precision : 'Precision Cannot be empty'}
                            sx={{ width: '100%' }}
                            FormHelperTextProps={{
                                style: { color: errorForm.precision ? 'red' : '#FFF', fontWeight: errorForm.precision ? 'bold' : undefined }
                            }}
                            inputProps={{
                                style: { color: '#FFF' }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            value={fields.quoteDecimals}
                            onChange={handleFieldsChange}
                            id="quoteDecimals"
                            label="QuoteDecimals"
                            helperText={errorForm.quoteDecimals ? errorText.quoteDecimals : 'QuoteDecimals Cannot be empty'}
                            sx={{ width: '100%' }}
                            FormHelperTextProps={{
                                style: {
                                    color: errorForm.quoteDecimals ? 'red' : '#FFF',
                                    fontWeight: errorForm.quoteDecimals ? 'bold' : undefined
                                }
                            }}
                            inputProps={{
                                style: { color: '#FFF' }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            value={fields.rewardContract}
                            onChange={handleFieldsChange}
                            id="rewardContract"
                            label="Reward Contract"
                            helperText={errorForm.rewardContract ? errorText.rewardContract : 'Reward Contract Cannot be empty'}
                            sx={{ width: '100%' }}
                            FormHelperTextProps={{
                                style: {
                                    color: errorForm.rewardContract ? 'red' : '#FFF',
                                    fontWeight: errorForm.rewardContract ? 'bold' : undefined
                                }
                            }}
                            inputProps={{
                                style: { color: '#FFF' }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={(e) => onPaste('rewardContract', e)}>
                                        <ContentPaste sx={{ color: 'white' }} />
                                    </IconButton>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            value={fields.stakeContract}
                            onChange={handleFieldsChange}
                            id="stakeContract"
                            label="Stake Contract"
                            helperText={errorForm.stakeContract ? errorText.stakeContract : 'Stake Contract Cannot be empty'}
                            sx={{ width: '100%' }}
                            FormHelperTextProps={{
                                style: {
                                    color: errorForm.stakeContract ? 'red' : '#FFF',
                                    fontWeight: errorForm.stakeContract ? 'bold' : undefined
                                }
                            }}
                            inputProps={{
                                style: { color: '#FFF' }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={(e) => onPaste('stakeContract', e)}>
                                        <ContentPaste sx={{ color: 'white' }} />
                                    </IconButton>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            value={fields.totalRewards}
                            onChange={handleFieldsChange}
                            id="totalRewards"
                            label="Total Rewards"
                            helperText={errorForm.totalRewards ? errorText.totalRewards : 'Total Rewards Cannot be empty'}
                            sx={{ width: '100%' }}
                            FormHelperTextProps={{
                                style: {
                                    color: errorForm.totalRewards ? 'red' : '#FFF',
                                    fontWeight: errorForm.totalRewards ? 'bold' : undefined
                                }
                            }}
                            inputProps={{
                                style: { color: '#FFF' }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Stack direction={'column'} spacing={1}>
                            <Typography>Start Date</Typography>
                            <StakingDatePicker
                                id={'startDate'}
                                onResultCallback={onDatePickerChange}
                                label="Start Date"
                                defValue={defValue.startDate}
                            />
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction={'column'} spacing={1}>
                            <Typography>Ended Date</Typography>
                            <StakingDatePicker
                                id={'endedDate'}
                                onResultCallback={onDatePickerChange}
                                label="Ended Date"
                                defValue={defValue.endedDate}
                            />
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction={'column'} spacing={1}>
                            <Typography>Preparation Date</Typography>
                            <StakingDatePicker
                                id={'preparationDate'}
                                onResultCallback={onDatePickerChange}
                                label="Preparation Date"
                                defValue={defValue.preparationDate}
                            />
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction={'column'} spacing={1}>
                            <Typography>Stake Date</Typography>
                            <StakingDatePicker
                                id={'stakeDate'}
                                onResultCallback={onDatePickerChange}
                                label="Stake Date"
                                defValue={defValue.stakeDate}
                            />
                        </Stack>
                    </Grid>
                    <Grid item alignSelf={'end'}>
                        <Stack direction={'row'}>
                            <Button
                                placement="end"
                                onClick={saveData}
                                style={{
                                    background: 'transparent',
                                    borderColor: 'grey',
                                    borderRadius: 12,
                                    '&:hover': {
                                        background: 'red',
                                        borderColor: 'transparent'
                                    },
                                    marginLeft: 10
                                }}
                            >
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

AddStakingModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

export default AddStakingModal;
