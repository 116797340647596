import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { createData } from '../utils/core.utils';
import { toCurrency, toSmallUnit } from '../utils/string.utils';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Check, Delete, DoNotDisturb, Edit } from '@mui/icons-material';

export default function StakingListTable({ mappedStakingData, onClickEdit, onClickDelete, onClickDeactive }) {
    const onEdit = (
        APY = '',
        baseErcDecimal = '',
        base_contract = '',
        day = '',
        ercSymbol = '',
        maxAmount = '',
        maxParticipant = '',
        minAmount = '',
        name = '',
        precision = '',
        quoteDecimals = '',
        rewardContract = '',
        stakeContract = '',
        status = 'publish',
        totalRewards = '',
        id,
        startDate = new Date().getTime(),
        endedDate = new Date().getTime(),
        preparationDate = new Date().getTime(),
        baseTokenImage = '',
        rewardTokenImage = ''
    ) => {
        console.log(
            {
                startDate,
                endedDate,
                preparationDate
            },
            '@reward?'
        );
        const obj = {
            APY,
            baseErcDecimal,
            base_contract,
            day,
            ercSymbol,
            maxAmount,
            maxParticipant,
            minAmount,
            name,
            precision,
            quoteDecimals,
            rewardContract,
            stakeContract,
            status,
            totalRewards,
            id,
            startDate,
            endedDate,
            preparationDate,
            baseToken: baseTokenImage,
            rewardToken: rewardTokenImage
        };
        onClickEdit(obj);
    };
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Min-Max Stake</TableCell>
                        <TableCell align="right">APY&nbsp;(%)</TableCell>
                        <TableCell align="right">Max Participant</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mappedStakingData.length > 0 ? (
                        mappedStakingData.map((row) => (
                            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {row.data().name}
                                </TableCell>
                                <TableCell align="right">
                                    {toCurrency(toSmallUnit(row.data().minAmount, row.data().baseErcDecimal))} -{' '}
                                    {toCurrency(toSmallUnit(row.data().maxAmount, row.data().baseErcDecimal))}
                                </TableCell>
                                <TableCell align="right">{row.data().APY}</TableCell>
                                <TableCell align="right">{row.data().maxParticipant}</TableCell>
                                <TableCell align="right">{row.data().status}</TableCell>
                                <TableCell align="right">
                                    <Stack direction={'row'}>
                                        <Tooltip title="Edit data">
                                            <IconButton
                                                onClick={() =>
                                                    onEdit(
                                                        row.data().APY.replace('%', ''),
                                                        row.data().baseErcDecimal,
                                                        row.data().base_contract,
                                                        row.data().day,
                                                        row.data().ercSymbol,
                                                        row.data().maxAmount,
                                                        row.data().maxParticipant,
                                                        row.data().minAmount,
                                                        row.data().name,
                                                        row.data().precision,
                                                        row.data().quoteDecimals,
                                                        row.data().rewardContract,
                                                        row.data().stakeContract,
                                                        row.data().status,
                                                        row.data().totalRewards,
                                                        row.id,
                                                        (row.data().startDate = new Date().getTime()),
                                                        (row.data().endedDate = new Date().getTime()),
                                                        (row.data().preparationDate = new Date().getTime()),
                                                        (row.data().baseTokenImage = ''),
                                                        (row.data().rewardTokenImage = '')
                                                    )
                                                }
                                            >
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete data">
                                            <IconButton onClick={() => onClickDelete(row.id)}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Deactive data">
                                            <IconButton onClick={() => onClickDeactive(row.id, row.data().status !== 'publish' ? 1 : 0)}>
                                                {row.data().status !== 'publish' ? <Check /> : <DoNotDisturb />}
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <Typography sx={{ m: 2 }} textAlign="center" alignItems={'center'} justifyContent="center">
                            No Data Displayed
                        </Typography>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
