import React, { useEffect, useState, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { firebaseConfig } from '../config/firebase.config';
import { collection, getFirestore, getDocs, query, orderBy, doc, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { StakingModel } from '../models/stakingModel';
const isDev = false;
const refName = isDev ? 'StakingDevData' : 'StakingProdData';
export const FirebaseContext = React.createContext(null);
export const FirebaseProvider = ({ children }) => {
    const [db, setDb] = useState(null);
    const [analytics, setAnalytics] = useState(null);
    const [firestore, setFirestore] = useState(null);

    const updateStakingData = async (
        APY = '',
        baseErcDecimal = '',
        base_contract = '',
        day = '',
        ercSymbol = '',
        maxAmount = '',
        maxParticipant = '',
        minAmount = '',
        name = '',
        precision = '',
        quoteDecimals = '',
        rewardContract = '',
        stakeContract = '',
        status = 'publish',
        totalRewards = '',
        collectionId,
        startDate = new Date().getTime(),
        endedDate = new Date().getTime(),
        preparationDate = new Date().getTime(),
        baseTokenImage = '',
        rewardTokenImage = ''
    ) => {
        try {
            const stakingModel = new StakingModel(
                APY,
                baseErcDecimal,
                base_contract,
                day,
                ercSymbol,
                maxAmount,
                maxParticipant,
                minAmount,
                name,
                precision,
                quoteDecimals,
                rewardContract,
                stakeContract,
                (status = 'publish'),
                totalRewards,
                startDate,
                endedDate,
                preparationDate,
                baseTokenImage,
                rewardTokenImage
            );

            const ref = doc(firestore, refName, collectionId);
            await updateDoc(ref, stakingModel);
            return 'success';
        } catch (err) {
            console.log(err, '@errorOnUseFirebase!!');
            return err;
        }
    };

    const addStakingData = async (
        APY = '',
        baseErcDecimal = '',
        base_contract = '',
        day = '',
        ercSymbol = '',
        maxAmount = '',
        maxParticipant = '',
        minAmount = '',
        name = '',
        precision = '',
        quoteDecimals = '',
        rewardContract = '',
        stakeContract = '',
        status = 'publish',
        totalRewards = '',
        startDate = new Date().getTime(),
        endedDate = new Date().getTime(),
        preparationDate = new Date().getTime(),
        baseTokenImage = '',
        rewardTokenImage = ''
    ) => {
        try {
            const stakingModel = new StakingModel(
                APY,
                baseErcDecimal,
                base_contract,
                day,
                ercSymbol,
                maxAmount,
                maxParticipant,
                minAmount,
                name,
                precision,
                quoteDecimals,
                rewardContract,
                stakeContract,
                (status = 'publish'),
                totalRewards,
                startDate,
                endedDate,
                preparationDate,
                baseTokenImage,
                rewardTokenImage
            );

            const ref = collection(firestore, refName);
            const add = await addDoc(ref, stakingModel);
            return add.id;
        } catch (err) {
            console.log(err, '@errorOnUseFirebase!!');
            return err;
        }
    };

    const readDocs = async () => {
        try {
            const ref = collection(firestore, refName);
            const q = query(ref, orderBy('id', 'desc'));
            const result = await getDocs(q);
            if (result) {
                let temp = [];
                result.forEach((data) => {
                    return temp.push(data);
                });
                return temp;
            }
        } catch (err) {
            console.log(err.message, '@err get docs');
            return null;
        }
    };

    const readStaking = async () => {
        try {
            const ref = collection(firestore, refName);
            const q = query(ref, orderBy('id', 'desc'));
            const result = await getDocs(q);
            if (result) {
                let temp = [];
                result.forEach((data) => {
                    return temp.push(data.data());
                });
                return temp;
            }
        } catch (err) {
            console.log(err.message, '@err get docs');
            return null;
        }
    };

    const deactiveStaking = async (id, status) => {
        try {
            const ref = doc(firestore, refName, id);
            await updateDoc(ref, {
                status: status === 1 ? 'publish' : 'deactive'
            });
            return true;
        } catch (err) {
            console.log(err, '@errorUpdateFirebase');
            return false;
        }
    };

    const deleteStaking = async (id) => {
        try {
            const ref = doc(firestore, refName, id);
            await deleteDoc(ref);
            return true;
        } catch (err) {
            console.log(err, '@errorUpdateFirebase');
            return false;
        }
    };

    const init = async () => {
        try {
            const _db = await initializeApp(firebaseConfig);
            const _analytics = await getAnalytics(_db);

            if (_db && _analytics) {
                setFirestore(getFirestore());
                setDb(_db);
                setAnalytics(_analytics);
                console.log('successfully initializing firebase!');
            }
        } catch (err) {
            console.log(err);
            throw new Error(err.message);
        }
    };

    useEffect(() => {
        init();
    }, [null]);

    const values = useMemo(
        () => ({
            db,
            analytics,
            firestore,
            readStaking,
            addStakingData,
            readDocs,
            deactiveStaking,
            deleteStaking,
            updateStakingData
        }),
        [db, analytics, firestore]
    );

    return <FirebaseContext.Provider value={values}>{children}</FirebaseContext.Provider>;
};

export default function useFirebase() {
    const context = React.useContext(FirebaseContext);
    if (context === undefined) {
        throw new Error('useFirebase hook must be with a useFirebaseProvider');
    }
    return context;
}
