// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: 'AIzaSyDOozEZMt4ZkaQwf392hj83S21O3Z1CTwY',
    authDomain: 'staking-64c54.firebaseapp.com',
    projectId: 'staking-64c54',
    storageBucket: 'staking-64c54.appspot.com',
    messagingSenderId: '266938477186',
    appId: '1:266938477186:web:d904c035f02d8792b5e29b',
    measurementId: 'G-3TMDYYZ6EY'
};
