import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, IconButton, Modal, Stack, Typography } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import useFirebase from '../hooks/useFirebase';
import StakingListTable from './StakingListTable';
import useLoader from '../hooks/useLoader';
import useSwal from '../hooks/useSwal';
import AddStakingModal from './AddStakingModal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 1000,
    background: 'rgb(8, 45, 95) 100%',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
    maxHeight: 700,
    overflow: 'scroll',
    zIndex: 10000
};

const AdminModal = ({ open, handleClose, onRefreshCallback }) => {
    const firebase = useFirebase();
    const { firestore, addStakingData, readDocs, deactiveStaking, deleteStaking } = firebase;
    const [stakingData, setStakingData] = useState([]);
    const [openAddModal, setOpenAddModal] = useState(false);
    const { startLoading, stopLoading } = useLoader();
    const [isEdit, setIsEdit] = useState(false);
    const [defValue, setDefValue] = useState({
        APY: '',
        baseErcDecimal: '',
        base_contract: '',
        day: '',
        ercSymbol: '',
        maxAmount: '',
        maxParticipant: '',
        minAmount: '',
        name: '',
        precision: '',
        quoteDecimals: '',
        rewardContract: '',
        stakeContract: '',
        status: 'publish',
        totalRewards: ''
    });
    const { confirm } = useSwal();

    const get = async () => {
        try {
            startLoading('Get Data ...');
            const docs = await readDocs();

            if (docs !== null) {
                stopLoading();
                setStakingData(docs);
                return docs;
            }
            return false;
        } catch (err) {
            console.log(err, '@error get firebase');
            stopLoading();
            return false;
        }
    };

    useEffect(() => {
        if (firestore !== null) {
            get();
        }
    }, [firestore]);

    const onClickEdit = (data = {}, id = '') => {
        setIsEdit(true);
        addStaking();
        setDefValue(data);
    };

    const onClickDelete = async (id) => {
        try {
            confirm('Delete data?', `You won't able to revert this`, 'warning', async () => {
                startLoading('Deleting ...');
                const deleteData = await deleteStaking(id);
                if (deleteData) {
                    stopLoading();
                    get();
                }
            });
        } catch (err) {
            stopLoading();
            console.log(err, '@error delete');
        }
    };

    const onClickDeactive = async (id, status) => {
        try {
            startLoading();
            const deactive = await deactiveStaking(id, status);
            if (deactive) {
                stopLoading();
                get();
            }
        } catch (err) {
            stopLoading();
        }
    };

    const onClickAddData = async () => {
        addStaking();
    };

    const addStaking = () => {
        setOpenAddModal(true);
    };

    const closeStaking = (refresh) => {
        if (refresh) {
            get();
            onRefreshCallback();
        }
        setDefValue({
            APY: '',
            baseErcDecimal: '',
            base_contract: '',
            day: '',
            ercSymbol: '',
            maxAmount: '',
            maxParticipant: '',
            minAmount: '',
            name: '',
            precision: '',
            quoteDecimals: '',
            rewardContract: '',
            stakeContract: '',
            status: 'publish',
            totalRewards: ''
        });
        setIsEdit(false);
        setOpenAddModal(false);
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid container direction={'column'} spacing={2} justifyContent={'center'}>
                    <Grid item>
                        <Stack direction="row" width="100%" justifyContent="space-between" alignItems={'center'} justifyItems={'center'}>
                            <Typography textAlign={'center'}>Admin Events</Typography>
                            <IconButton onClick={handleClose}>
                                <Close sx={{ color: '#FFFF' }} />
                            </IconButton>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction={'column'} spacing={2}>
                            <Typography variant="h5" component={'div'} fontWeight="bold">
                                Staking Data
                            </Typography>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Typography fontSize={15} sx={{ color: 'grey' }}>
                                    List of staking data, you can edit or delete staking data
                                </Typography>
                                <Button variant="text" endIcon={<Add />} sx={{ color: '#FFFF' }} onClick={onClickAddData}>
                                    <Typography fontSize={15} fontWeight={'bold'} sx={{ color: 'grey' }}>
                                        Add Data
                                    </Typography>
                                </Button>
                            </Stack>
                            <StakingListTable
                                mappedStakingData={stakingData}
                                onClickEdit={onClickEdit}
                                onClickDelete={onClickDelete}
                                onClickDeactive={onClickDeactive}
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <AddStakingModal open={openAddModal} handleClose={closeStaking} isEdit={isEdit} defValue={defValue} />
            </Box>
        </Modal>
    );
};

AdminModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

export default AdminModal;
