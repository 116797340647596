import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { convertDecimal } from '../utils/convertDecimal';

function ProgressBarMenu({totalReward, rewardBalance, decimal}) {

    return (
        <div className="progressInfo pt-3">
            <div>
                <span className="" style={{ fontSize: '12px' }}>
                    Distributed Tokens:{' '}
                {/* <span>{rewardBalance}</span> */}
                </span>
            </div>
            <div className="">
                <ProgressBar
                    variant="success"
                    style={{
                        backgroundColor: 'rgba(20, 163, 139, 0.3)'
                    }}
                    sx={{ borderRadius: '30px' }}
                    now={
                        ((totalReward-rewardBalance) / totalReward) * 100
                    }
                />
            </div>
            <div className="currentProgress d-flex" style={{ justifyContent: 'flex-end', fontSize: '12px' }}>
                <span>{convertDecimal(totalReward - rewardBalance, decimal)} / {convertDecimal(totalReward, decimal)}</span>
            </div>
        </div>
    );
}

export default ProgressBarMenu;
