import React from 'react';
import {
    Routes,
    Route
    // Navigate
} from 'react-router-dom';
import { HOME_ROUTE, STAKING_ROUTE, FARMING_ROUTE } from './constants/routers';
import Farming from './pages/Farming.page';
import Staking from './pages/Stake.page';

export default function AppRoutes() {
    return (
        <Routes>
            <Route path={HOME_ROUTE} element={<Staking />} />
            <Route path={FARMING_ROUTE} element={<Farming />} />
        </Routes>
    );
}
