import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { withStyles } from '@mui/styles';

const CustomDateTimePicker = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'white'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'yellow'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white'
            },
            '&:hover fieldset': {
                borderColor: 'white'
            },
            '&.Mui-focused fieldset': {
                borderColor: 'yellow'
            }
        }
    }
})(TextField);

export default function StakingDatePicker({ id, onResultCallback, label, defValue = 0 }) {
    const [value, setValue] = React.useState(new Date());

    React.useEffect(() => {
        if (defValue !== 0) {
            setValue(new Date(defValue));
        }
    }, [defValue]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                renderInput={(props) => (
                    <CustomDateTimePicker
                        InputLabelProps={{ style: { color: '#FFF' } }}
                        SelectProps={{ style: { color: '#FFF' } }}
                        {...props}
                    />
                )}
                value={value}
                label={label}
                onChange={(newValue) => {
                    setValue(newValue);
                    onResultCallback(id, newValue.getTime());
                }}
                InputAdornmentProps={{
                    style: {
                        color: '#FFF'
                    }
                }}
                PopperProps={{
                    style: {
                        color: '#FFF'
                    }
                }}
                InputProps={{
                    id: id,
                    style: {
                        color: '#FFF'
                    }
                }}
            />
        </LocalizationProvider>
    );
}
