import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, IconButton, InputAdornment, Modal, OutlinedInput, Stack, Typography, CircularProgress } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { OnSale } from '../assets/svgs/OnSale';
import { Close } from '@mui/icons-material';
import ttd from '../assets/ttdLogo.svg';
import bnb from '../assets/bnbLogo.svg';
import moment from 'moment';
import useLoader from '../hooks/useLoader';
import Web3 from 'web3';
import { toSmallUnit } from '../utils/string.utils';
import nftAbi from '../abis/NFTToken.json';
import { Button } from 'react-bootstrap';
import { withStyles } from '@mui/styles';
import { convertDecimal } from 'utils/convertDecimal';
import useSwal from 'hooks/useSwal';
import api from '../utils/baseUrl.utils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 526,
  background: '#0E0A38',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto'
};

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white'
    },
    '& label': {
      color: 'white'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white'
      },
      '&:hover fieldset': {
        borderColor: 'white'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white'
      }
    }
  }
})(OutlinedInput);

const calculateDuration = (eventTime) => moment.duration(Math.max(eventTime - Math.floor(Date.now() / 1000), 0), 'seconds');

function Countdown({ eventTime, interval, phase }) {
  const [duration, setDuration] = useState(calculateDuration(eventTime));
  const timerRef = useRef(0);
  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime));
  }, [eventTime]);

  const title = {
    0: 'Prepare Time',
    1: 'Start Time',
    2: 'Stake Time',
    3: 'Prepare Time'
  };

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, interval);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [eventTime]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <>
        <p className="presaleTitle">{title[phase]} in</p>
        <div
          className="daysAndTime"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row'
          }}
        >
          <div
            className="HMS"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row'
            }}
          >
            <div className="time">
              <p
                className="number"
                style={{
                  fontSize: '25px',
                  margin: '0 .7rem'
                }}
              >
                {(duration.days() + duration.months() * 30).toString().length < 2
                  ? '0' + duration.days()
                  : duration.days() + duration.months() * 30}
              </p>
              <span>Days</span>
            </div>
            <p
              className="number"
              style={{
                fontSize: '20px'
              }}
            >
              :
            </p>
            <div className="time">
              <p
                className="number"
                style={{
                  fontSize: '25px',
                  margin: '0 .7rem'
                }}
              >
                {duration.hours().toString().length < 2 ? '0' + duration.hours() : duration.hours()}
              </p>
              <span>Hours</span>
            </div>
            <p
              className="number"
              style={{
                fontSize: '20px'
              }}
            >
              :
            </p>
            <div className="time">
              <p
                className="number"
                style={{
                  fontSize: '25px',
                  margin: '0 .7rem'
                }}
              >
                {duration.minutes().toString().length < 2 ? '0' + duration.minutes() : duration.minutes()}
              </p>
              <span>Minutes</span>
            </div>
            <p
              className="number"
              style={{
                fontSize: '20px'
              }}
            >
              :
            </p>
            <div className="time">
              <p
                className="number"
                style={{
                  fontSize: '25px',
                  margin: '0 .7rem'
                }}
              >
                {duration.seconds().toString().length < 2 ? '0' + duration.seconds() : duration.seconds()}
              </p>
              <span>Seconds</span>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

const FarmingModal = ({
  index,
  open,
  handleClose,
  pair,
  day = '45',
  apy = '100%',
  minStake = '30000',
  maxStake = '50000',
  tvl = '45',
  stakingStartDate = '03/20/2021 18.00 GMT+8',
  stakingEndDate = '',
  baseContract = '',
  baseErcDecimal = '',
  ercSymbol,
  unlocksIn,
  rewardPerMinute,
  userShare,
  totalParticipants,
  stake,
  symbol,
  approved,
  web3,
  lpInstance,
  address,
  scAddress,
  instanceFarming,
  balance,
  deposit,
  decimal,
  rewardPerBlock,
  chain,
  staked,
  paid,
  logo,
  endingBlock,
  currentBlock,
}) => {
  const { startLoading, stopLoading } = useLoader();

  // const [balance, setBalance] = useState(balance);
  const [loading, setLoading] = useState(false);
  const [loadingClaim, setLoadingClaim] = useState(false);
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);
  const [newApproved, setNewApproved] = useState(approved);
  const [value, setValue] = useState(0);

  const { errror, info } = useSwal();

  useEffect(() => {
    setNewApproved(approved);
  }, [approved]);

  // const getBalance = async () => {
  //   try {
  //     const getAccount = await localStorage.getItem('account');
  //     const web3 = new Web3(Web3.givenProvider || new Web3.providers.HttpProvider('https://rpc-bsc-main-01.3tokendigital.com/rpc'));
  //     const getBalance = await web3.eth.getBalance(getAccount); //BNB
  //     const ercContract = new web3.eth.Contract(nftAbi, baseContract); //erc contract
  //     const ercBalance = await ercContract.methods.balanceOf(getAccount).call();
  //     console.log(ercBalance, '@balance??');
  //     if (ercBalance) {
  //       setBalance(toSmallUnit(ercBalance, baseErcDecimal));
  //     } else {
  //       setBalance('0');
  //     }
  //   } catch (err) {
  //     // alert(err.message)
  //     console.log(err, '@error');
  //   }
  // };

  // useEffect(() => {
  //   getBalance();
  // }, [null]);

  const approve = async () => {
    setLoading(true);
    console.log(lpInstance, "@271");
    try {
      const result = await lpInstance.methods.approve(scAddress, web3.utils.toWei('99999999999999999999999999999', 'ether')).send({
        from: address,
        gasPrice: '20000000000'
      });

      console.log(result, '@249');
      setLoading(false);
      info('success', 'you are approved');
      setNewApproved(true);
    } catch (error) {
      console.log(error, '@251');
      setLoading(false);
      errror(error.message);
    }
  };

  const farm = async () => {
    setLoading(true);
    try {
      const result = await instanceFarming.methods.stake((value * Math.pow(10, decimal)).toString()).send({
        from: address,
        gas: 360000
      });
      console.log(result);
      setLoading(false);
      info('success', 'Farming success');
    } catch (error) {
      setLoading(false);
      errror(error.message);
    }
  };

  const claim = async () => {
    setLoadingClaim(true);
    try {
      const claim = await instanceFarming.methods.claimRewards().send({
        from: address,
        gas: 210000
      });
      console.log(claim);
      setLoadingClaim(false);
      info('success', 'Claim success');
    } catch (error) {
      setLoadingClaim(false);
      console.log(error);
      errror(error.message);
    }
  };

  const withdraw = async () => {
    setLoadingWithdraw(true);
    try {
      const withdraw = await instanceFarming.methods.withdraw(deposit).send({
        from: address,
        gas: 210000
      });
      console.log(withdraw);
      setLoadingWithdraw(false);
      info('success', 'Withdraw success');
    } catch (error) {
      console.log(error);
      setLoadingWithdraw(false);
      errror(error.message);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Grid container direction={'column'} spacing={2}>
          <Grid item width={'100%'}>
            <Stack justifyContent={'space-between'} direction={'row'} width="100%" alignItems={'center'} justifyItems={'center'}>
              <OnSale />
              <IconButton onClick={handleClose}>
                <Close sx={{ color: '#FFFF' }} color="inherit" />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item>
            <div
              className="d-flex"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
                height: '100px'
              }}
            >
              <img
                src={api.BASE_URL + logo}
                alt="ttd"
                style={{
                  position: 'absolute',
                  left: 0,
                  marginLeft: '33%',
                  top: '10%',
                  width: '20%',
                  zIndex: 10
                }}
              />
              {chain ? (
                <img
                  src={api.BASE_URL + chain.data.attributes.ChainLogo.data.attributes.url}
                  alt="bnb"
                  style={{
                    position: 'absolute',
                    right: 0,
                    marginRight: '33%',
                    top: '10%',
                    width: '20%'
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </Grid>
          <Grid item alignSelf={'center'}>
            <Typography variant="h4" component={'div'}>
              {symbol} - {pair}
            </Typography>
          </Grid>
          {/* apy */}
          <Grid item width={'100%'}>
            <Stack justifyContent={'space-between'} direction={'row'} width="100%" alignItems={'center'} justifyItems={'center'}>
              <Typography fontWeight={'bold'}>APY</Typography>
              <Typography fontWeight={'bold'}>{apy} %</Typography>
            </Stack>
          </Grid>
          {/* reward per minute */}
          <Grid item width={'100%'}>
            <Stack justifyContent={'space-between'} direction={'row'} width="100%" alignItems={'center'} justifyItems={'center'}>
              <Typography fontWeight={'bold'}>Reward Per Minute</Typography>
              <Typography fontWeight={'bold'}>
                {Number(convertDecimal(rewardPerMinute * rewardPerBlock, decimal)).toFixed(4)} {symbol}
              </Typography>
            </Stack>
          </Grid>
          {/* unlock */}
          <Grid item width={'100%'}>
            <Stack justifyContent={'space-between'} direction={'row'} width="100%" alignItems={'center'} justifyItems={'center'}>
              <Typography fontWeight={'bold'}>Unlocks in</Typography>
              <Typography fontWeight={'bold'}>
                {Math.round(unlocksIn / 24)} Day(s) {Math.round(unlocksIn % 24)} Hour(s){' '}
              </Typography>
            </Stack>
          </Grid>
          {/* your stake */}
          <Grid item width={'100%'}>
            <Stack justifyContent={'space-between'} direction={'row'} width="100%" alignItems={'center'} justifyItems={'center'}>
              <Typography fontWeight={'bold'}>Your stake</Typography>
              <Typography fontWeight={'bold'}>
                {convertDecimal(stake, decimal)} {symbol}-LP
              </Typography>
            </Stack>
          </Grid>
          {/* your deposits */}
          <Grid item width={'100%'}>
            <Stack justifyContent={'space-between'} direction={'row'} width="100%" alignItems={'center'} justifyItems={'center'}>
              <Typography fontWeight={'bold'}>Your deposit</Typography>
              <Typography fontWeight={'bold'}>
                {convertDecimal(deposit, decimal)} {symbol}
              </Typography>
            </Stack>
          </Grid>
          {/* pool share */}
          <Grid item width={'100%'}>
            <Stack justifyContent={'space-between'} direction={'row'} width="100%" alignItems={'center'} justifyItems={'center'}>
              <Typography fontWeight={'bold'}>Pool Share</Typography>
              <Typography fontWeight={'bold'}>{userShare} %</Typography>
            </Stack>
          </Grid>
          {/* participants */}
          <Grid item width={'100%'}>
            <Stack justifyContent={'space-between'} direction={'row'} width="100%" alignItems={'center'} justifyItems={'center'}>
              <Typography fontWeight={'bold'}>Participants</Typography>
              <Typography fontWeight={'bold'}>{totalParticipants}</Typography>
            </Stack>
          </Grid>
          {/* others */}
          <Grid item width={'100%'} m={1}>
            <Divider variant="middle" />
          </Grid>
          {/* date */}
          {/* <Grid item width={'100%'}>
            <Stack direction={'column'} spacing={2}>
              <Typography>This Stake Ends in:</Typography>
              <Countdown eventTime={stakingEndDate} interval={1000} phase={1} />
            </Stack>
          </Grid> */}
          {/* <Grid item width={'100%'}>
            <Divider variant="fullWidth" />
          </Grid> */}
          {/* balance */}
          <Grid item width={'100%'}>
            <Stack direction={'column'} spacing={2}>
              <Typography>Balance: {convertDecimal(balance, decimal)}</Typography>
            </Stack>
          </Grid>
          <Grid item p={2}>
            <Stack direction={'column'} spacing={0}>
              {newApproved ? (
                !loading ? (
                  <>
                    {' '}
                    {/* <Typography>
                      Your Balance : {balance} {ercSymbol}
                    </Typography> */}
                    <OutlinedInput
                      id="outlined-adornment-text"
                      type={'number'}
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      // disabled={true}
                      inputProps={{
                        style: { color: '#FFF', padding: 6, height: 50 }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <Button
                            variant="secondary"
                            style={{
                              background: 'linear-gradient(255.52deg, #0294AB -173.55%, #0294AB 71.9%, rgba(60, 89, 190, 0.7) 117.57%)',
                              borderColor: 'transparent'
                            }}
                            className="w-100"
                            onClick={() => {
                              farm();
                            }}
                          >
                            Farm
                          </Button>
                        </InputAdornment>
                      }
                      startAdornment={
                        <InputAdornment position="start">
                          <img src={ttd} alt="ttd" width={30} height={30} />
                        </InputAdornment>
                      }
                      label="Balance"
                    />
                  </>
                ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                  </Box>
                )
              ) : !loading ? (
                <Button
                  variant="secondary"
                  style={{
                    background: 'linear-gradient(255.52deg, #0294AB -173.55%, #0294AB 71.9%, rgba(60, 89, 190, 0.7) 117.57%)',
                    borderColor: 'transparent'
                  }}
                  className="w-100"
                  onClick={() => approve()}
                >
                  Approve
                </Button>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
              )}
              <div className="col-12">
                <Button
                  className="w-100"
                  variant="warning"
                  style={{
                    color: 'white',
                    borderColor: 'transparent',
                    marginTop: "1rem"
                  }}
                  onClick={() => claim()}
                  disabled={!staked || paid || currentBlock < endingBlock}
                >
                  {!approved ? 'Please approve first' : loadingClaim ? <CircularProgress /> : 'Claim Rewards'}
                </Button>
              </div>
              <div className="col-12">
                <Button
                  className="w-100"
                  variant="secondary"
                  style={{
                    color: 'white',
                    borderColor: 'transparent',
                    marginTop: "1rem"
                  }}
                  onClick={() => withdraw()}
                  disabled={!staked || currentBlock < endingBlock}
                >
                  {!approved ? 'Please approve first' : loadingWithdraw ? <CircularProgress /> : 'Withdraw'}
                </Button>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

FarmingModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export default FarmingModal;
