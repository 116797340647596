// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/bg-corner.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  box-sizing: border-box;\n}\n\nhtml {\n  font-family: sans-serif;\n  height: 100%; margin: 0px; padding: 0px;\n}\n\n/* #full { height: 100% } */\n\nbody {\n  background: #0B0547;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  color: white;\n  /* height: 100%; margin: 0px; padding: 0px; */\n}\n\n.loader{\n  margin: 0 0 2em;\n  height: auto;\n  width: 20%;\n  text-align: center;\n  padding: 1em;\n  margin: auto;\n  vertical-align: center;\n}\n\n.swal2-container {\n  z-index: 19000;\n}\n\n.swal2-title {\n  color: white;\n}\n\n.swal2-html-container, .swal2-title{\n  color: white !important;\n}", "",{"version":3,"sources":["webpack://src/styles/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,YAAY,EAAE,WAAW,EAAE,YAAY;AACzC;;AAEA,2BAA2B;;AAE3B;EACE,mBAAmB;EACnB,yDAAgD;EAChD,4BAA4B;EAC5B,YAAY;EACZ,6CAA6C;AAC/C;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,UAAU;EACV,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["* {\n  box-sizing: border-box;\n}\n\nhtml {\n  font-family: sans-serif;\n  height: 100%; margin: 0px; padding: 0px;\n}\n\n/* #full { height: 100% } */\n\nbody {\n  background: #0B0547;\n  background-image: url(\"../assets/bg-corner.png\");\n  background-repeat: no-repeat;\n  color: white;\n  /* height: 100%; margin: 0px; padding: 0px; */\n}\n\n.loader{\n  margin: 0 0 2em;\n  height: auto;\n  width: 20%;\n  text-align: center;\n  padding: 1em;\n  margin: auto;\n  vertical-align: center;\n}\n\n.swal2-container {\n  z-index: 19000;\n}\n\n.swal2-title {\n  color: white;\n}\n\n.swal2-html-container, .swal2-title{\n  color: white !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
