import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Farms from '../components/Farms';
import Navmenu from '../components/Navmenu';
import useWeb3 from '../hooks/useWeb3';
import axios from 'axios';
import Web3 from 'web3'
import api from '../utils/baseUrl.utils';
import walletConnectProvider from 'lib/connectWallet';

function Farming() {
  // const web3 = useWeb3(
  //   Web3.givenProvider || new Web3.providers.HttpProvider('https://rpc-bsc-main-01.3tokendigital.com/rpc')
  // );
  const [data, setData] = useState([]);

  const { account, isConnect, provider } = walletConnectProvider;

  // const { address, provider } = web3;

  console.log(walletConnectProvider, "@18");

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const farmData = await axios.get(`${api.BASE_URL}${api.ENDPOINT.farming}?${api.ENDPOINT.populate}chain&${api.ENDPOINT.populate}logo`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`
        }
      });
      console.log(farmData.data.data, '@30');
      setData(farmData.data.data);
    } catch (error) {
      console.log(error, "@30");
    }
  };

  return (
    <>
      <Navmenu />
      <Container>
        <div>
          {/* <div>
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '40px',
                marginBottom: '4rem',
              }}
            >
              Farming
            </span>
          </div> */}
          <div
            className="d-flex"
            style={{
              flexDirection: 'row',
              justifyContent: 'space-around',
              flexWrap: 'wrap'
            }}
          >
            {data.map((ele, idx) => {
              return <Farms 
              index={idx} 
              data={ele} 
              // provider={provider} 
              // address={account}
              />;
            })}
          </div>
        </div>
      </Container>
    </>
  );
}

export default Farming;
