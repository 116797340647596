import LoadingModal from '../components/LoadingModal';
import React, { useMemo, useState } from 'react';

export const LoaderContext = React.createContext(null);
export const LoaderProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('Loading ...');

    const stopLoading = () => {
        setLoading(false);
        setLoadingMsg('Loading ...');
    };

    const startLoading = (msg = 'Loading ...') => {
        setLoading(true);
        setLoadingMsg(msg);
    };

    const values = useMemo(
        () => ({
            stopLoading,
            startLoading
        }),
        [loading, loadingMsg]
    );

    return (
        <LoaderContext.Provider value={values}>
            {children}
            <LoadingModal open={loading} loadingMsg={loadingMsg} />
        </LoaderContext.Provider>
    );
};

export default function useLoader() {
    const context = React.useContext(LoaderContext);
    if (context === undefined) {
        throw new Error('useLoader hook must be with a useLoaderProvider');
    }
    return context;
}
