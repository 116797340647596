import { makeRandomDeg } from '../utils/core.utils';
export function StakingModel(
    apy,
    baseErcDecimal,
    base_contract,
    day,
    ercSymbol,
    maxAmount,
    maxParticipant,
    minAmount,
    name,
    precision,
    quoteDecimals,
    rewardContract,
    stakeContract,
    status,
    totalRewards,
    startDate,
    endedDate,
    preparationDate,
    baseTokenImage,
    rewardTokenImage
) {
    const objectModel = new Object();

    objectModel.id = makeRandomDeg();
    objectModel.APY = apy;
    objectModel.baseErcDecimal = baseErcDecimal;
    objectModel.base_contract = base_contract;
    objectModel.day = day;
    objectModel.ercSymbol = ercSymbol;
    objectModel.maxAmount = maxAmount;
    objectModel.maxParticipant = maxParticipant;
    objectModel.minAmount = minAmount;
    objectModel.name = name;
    objectModel.precision = precision;
    objectModel.quoteDecimals = quoteDecimals;
    objectModel.rewardContract = rewardContract;
    objectModel.stakeContract = stakeContract;
    objectModel.status = status;
    objectModel.totalRewards = totalRewards;
    objectModel.startDate = startDate;
    objectModel.endedDate = endedDate;
    objectModel.preparationDate = preparationDate;
    objectModel.baseTokenImage = baseTokenImage;
    objectModel.rewardTokenImage = rewardTokenImage;

    return objectModel;
}
