import React, { useState, useEffect } from 'react';
import FarmingModal from './FarmingModal';
import { Col, Row, Card, Button } from 'react-bootstrap';
import ProgressBarMenu from './progressBarMenu';
import { Box, CircularProgress } from '@mui/material';
import ttd from '../assets/ttdLogo.svg';
import bnb from '../assets/bnbLogo.svg';
import bsc from '../assets/bscScanLogo.svg';
import arrow from '../assets/Iconsarrow.svg';
import radar from '../assets/Iconsradar.svg';
import useWeb3 from '../hooks/useWeb3';
import useSwal from '../hooks/useSwal';
import Web3 from 'web3';
import farmingAbi from '../abis/Farming.json';
import pairingAbi from '../abis/Pairing.json';
import moment from 'moment';
import axios from 'axios';
import api from '../utils/baseUrl.utils';
import { convertDecimal } from '../utils/convertDecimal';
import walletConnectProvider from 'lib/connectWallet';


function Farms(props) {
  const { index, data } = props;
  const [isConnected, setIsConnected] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [unlocksIn, setUnlocksIn] = useState();
  const [rewardPerMinute, setRewardPerMinute] = useState(20);
  const [userShare, setUserShare] = useState(0);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [APY, setAPY] = useState(data.attributes.APY);
  const [totalReward, setTotalReward] = useState(0);
  const [rewardBalance, setRewardBalance] = useState(0);
  const [stake, setStake] = useState(0);
  const [symbol, setSymbol] = useState('');
  const [approved, setApproved] = useState(false);
  const [instanceLP, setInstanceLP] = useState(false);
  const [instanceFarmingSC, setInstanceFarmingSC] = useState();
  const [start, setStart] = useState(data.attributes.start);
  const [end, setEnd] = useState(data.attributes.end);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [staked, setHasStaked] = useState(false);
  const [paid, setIsPaid] = useState(false);
  const [deposits, setDeposits] = useState(0);
  const [decimal, setDecimal] = useState(0);
  const [rewardPerBlock, setRewPerBlock] = useState(0);
  const [chain, setChain] = useState();
  const [endingBlock, setEndingBlock] = useState();
  const [currentBlock, setCurrentBlock] = useState();

  const { triggerConnect } = useWeb3();
  const { account: address, isConnect, provider } = walletConnectProvider;
  console.log(walletConnectProvider, "@54");
  const modals = useSwal();

  const { errror, info } = modals;

  console.log(data, address, provider, props, "@data");

  useEffect(() => {
    console.log(address, provider, triggerConnect, "@63");
    if (address && provider) {
      init();
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
  }, [address && provider]);

  // const check = async () => {
  //   // if(!instanceLP) init();
  //   if (triggerConnect) setIsConnected(true);
  //   else setIsConnected(false);
  // }

  // useEffect(() => {
  //     check();
  // }, [triggerConnect]);

  const web3 = new Web3(
    provider ||
    Web3.givenProvider || 
    new Web3.providers.HttpProvider('https://rpc-bsc-main-01.3tokendigital.com/rpc' || 'https://bsc-dataseed.binance.org/' || 'https://bsc-dataseed1.defibit.io/')
  );
  const init = async () => {

    const instanceContract = new web3.eth.Contract(farmingAbi, data.attributes.scFarmingAddress, {
      from: address,
      gasPrice: '20000000000'
    });

    setInstanceFarmingSC(instanceContract);
    
    const lpInstance = new web3.eth.Contract(pairingAbi, data.attributes.pairingAddress, {
      from: address,
      gasPrice: '20000000000'
    });
    
    setInstanceLP(lpInstance);

    const getChain = await axios.get(`${api.BASE_URL}${api.ENDPOINT.chain}/${data.attributes.chain.data.id}?${api.ENDPOINT.populate}ChainLogo`);
    console.log(getChain.data, "@getChain");
    setChain(getChain.data);

    getData(instanceContract);
    getStakeData(lpInstance);
  };

  const getData = async (instance) => {
    try {
      // get lock duration
      const unlock = await instance.methods.lockDuration().call();
      setUnlocksIn(unlock);

      // get user share
      getUserShare(instance);

      // get participants
      const participants = await instance.methods.totalParticipants().call();
      setTotalParticipants(participants);

      // get total reward
      const totReward = await instance.methods.totalReward().call();
      setTotalReward(totReward);

      // get reward balance
      const rewBalance = await instance.methods.rewardBalance().call();
      setRewardBalance(rewBalance);

      // get reward per block
      const rewPerBlock = await instance.methods.rewPerBlock().call();
      setRewPerBlock(rewPerBlock);

      // get user deposit
      getUserDeposits(instance);

      // get ERC20Interface
      const ERC20Interface = await instance.methods.ERC20Interface().call();
      const LPInstance = new web3.eth.Contract(pairingAbi, ERC20Interface, {
        from: address,
        gasPrice: '20000000000'
      });

      setInstanceLP(LPInstance);

      // get allowance
      const allowance = await LPInstance.methods.allowance(address, instance._address).call();
      allowance > 0 ? setApproved(true) : setApproved(false);

      // get hasStaked
      const hasStaked = await instance.methods.hasStaked(address).call();
      setHasStaked(hasStaked);

      // get isPaid
      const isPaid = await instance.methods.isPaid(address).call();
      setIsPaid(isPaid);

      // get user deposits
      const userDeposits = await instance.methods.userDeposits(address).call();
      setDeposits(userDeposits[0]);

      // get end block
      const endBlock = await instance.methods.endingBlock().call();
      setEndingBlock(endBlock);

      // get current block
      const curBlock = await instance.methods.currentBlock().call();
      setCurrentBlock(curBlock);
    } catch (error) {
      console.log(error, "@163");
      errror(error.message);
    }
  };

  const getUserShare = async (instance) => {
    try {
      const share = await instance.methods.fetchUserShare(address).call();
      setUserShare(share/100);
    } catch (error) {
      console.log(error, '@100');
    }
  };

  const getUserDeposits = async (instance) => {
    try {
      const data = await instance.methods.calculate(address).call();
      setStake(data);
    } catch (error) {
      console.log(error, '@109');
    }
  };

  const getStakeData = async (instance) => {
    try {
      const symbol = await instance.methods.symbol().call();
      setSymbol(symbol);

      const balanceUser = await instance.methods.balanceOf(address).call();
      setBalance(balanceUser);

      const tokenDecimal = await instance.methods.decimals().call();
      setDecimal(tokenDecimal);
    } catch (error) {
      console.log(error, "@197");
      errror(error.message);
    }
  };

  return (
    <Col
      xs={3}
      className="d-flex"
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        background: 'rgba(255, 255, 255, 0.1)',
        margin: '0 0 3rem 1%',
        borderRadius:"15px"
      }}
    >
      <div className="p-4" style={{ width: '100%', height: '100%' }}>
        <div className="statuses" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            className={`d-flex align-items-center`}
            style={{
              borderRadius: '100px',
              padding: '4px 8px',
              background: 'rgba(20, 163, 139, 0.5)'
            }}
          >
            <img
              src={radar}
              alt="radar"
              // style={{
              //   position: "absolute",
              //   left: 0,
              //   marginLeft: "28%",
              //   top: "10%",
              //   width: "28%",
              //   zIndex: 10,
              // }}
            />
            <p
              className="statusText"
              style={{
                color: 'white',
                margin: '0px',
                fontSize: '14px',
                paddingLeft: '4px'
              }}
            >
              On progress
            </p>
          </div>
        </div>
        <Box
          className="logoAndTitleBox"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '2rem 0'
          }}
        >
          <Box
            className="logoBox"
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'relative'
            }}
          >
            <div
              className="d-flex"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
                height: '100px'
              }}
            >
              <img
                src={api.BASE_URL + data.attributes.logo.data.attributes.url}
                alt="ttd"
                style={{
                  position: 'absolute',
                  left: 0,
                  marginLeft: '28%',
                  top: '10%',
                  width: '28%',
                  zIndex: 1
                }}
              />
              {
                chain ? 
                <img
                  src={api.BASE_URL + chain.data.attributes.ChainLogo.data.attributes.url}
                  alt="bnb"
                  style={{
                    position: 'absolute',
                    right: 0,
                    marginRight: '28%',
                    top: '10%',
                    width: '28%'
                  }}
                />:<></>
              }
              {/* {api.BASE_URL + chain.data.attributes.ChainLogo.data.attributes.url} */}
            </div>
          </Box>
          <Card.Title className="d-flex" style={{ justifyContent: 'center' }}>
            {symbol} - {data.attributes.chain.data.attributes.ChainName}
          </Card.Title>
          <div className="d-flex" style={{ justifyContent: 'center' }}>
            {/* <a target="_blank" href={`https://testnet.bscscan.com/address/${data.attributes.scFarmingAddress}`}> */}
            <a target="_blank" href={`${data.attributes.link}`}>
              <img src={bsc} alt="bsc scan logo" />
            </a>
          </div>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <p className="stackingInfoTitle">See Pair Info</p>
            <a target="_blank" href={data.attributes.link}>
              <img src={arrow} alt="arrow" />
            </a>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <span className="h6">APY</span>
            <span className="h6">{data.attributes.APY} %</span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <span className="h6">Starts</span>
            <span className="h6">{moment(start).format('DD-MMM-YYYY hh:mm')}</span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <span className="h6">Ends</span>
            <span className="h6">{moment(end).format('DD-MMM-YYYY hh:mm')}</span>
          </div>
        </Box>
        <ProgressBarMenu totalReward={totalReward} rewardBalance={rewardBalance} decimal={decimal}/>
        <hr />
        {!isConnected ? (
          <Row>
            <div className="col-12">
              <Button
                variant="secondary"
                style={{
                  backgroundColor: 'grey'
                }}
                className="w-100"
                disabled={true}
              >
                Please connect to your account
              </Button>
            </div>
          </Row>
        ) : (
          <>
            <div className="col-12 mb-2">
              <Button
                className="w-100"
                style={{
                  background: 'linear-gradient(255.52deg, #0294AB -173.55%, #0294AB 71.9%, rgba(60, 89, 190, 0.7) 117.57%)',
                  borderColor: 'transparent'
                }}
                onClick={() => setOpenDetailModal(true)}
              >
                Detail
              </Button>
              {/* <Detail
                      stakeContract={props.stakeContract}
                      address={address}
                      phase={phase}
                    /> */}
            </div>
           
          </>
        )}
        <FarmingModal
          open={openDetailModal}
          handleClose={() => setOpenDetailModal(false)}
          index={index}
          unlocksIn={unlocksIn}
          rewardPerMinute={rewardPerMinute}
          userShare={userShare}
          totalParticipants={totalParticipants}
          apy={APY}
          stake={stake}
          symbol={symbol}
          approved={approved}
          web3={web3}
          lpInstance={instanceLP}
          address={address}
          scAddress={data.attributes.scFarmingAddress}
          instanceFarming={instanceFarmingSC}
          balance={balance}
          deposit={deposits}
          decimal={decimal}
          rewardPerBlock={rewardPerBlock}
          chain={chain}
          pair={chain ? chain.data.attributes.ChainName : ""}
          staked={staked}
          paid={paid}
          logo={data.attributes.logo.data.attributes.url}
          endingBlock={endingBlock}
          currentBlock={currentBlock}
          // stakingEndDate={props.preparationDate}
          // baseErcDecimal={props.baseErcDecimal}
          // baseContract={props.baseContract}
          // day={props.day}
          // maxStake={props.maxAmount}
          // minStake={props.minAmount}
          // apy={props.apy}
          // stakingStartDate={props.startDate}
        />
      </div>
    </Col>
  );
}

export default Farms;
