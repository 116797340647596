import React, { useMemo } from 'react';
import Swal from 'sweetalert2';

export const SwalContext = React.createContext(null);
export const SwalProvider = ({ children }) => {
    const errror = (title, msg) => {
        Swal.fire({
            title: title,
            text: msg,
            icon: 'error',
            confirmButtonColor: '#2675cf',
            background: 'linear-gradient(255.52deg, #0294AB -173.55%, #0294AB 71.9%, rgba(60, 89, 190, 0.7) 117.57%)'
        });
    };

    const info = (title, msg, icon = 'info') => {
        Swal.fire({
            title: title,
            text: msg,
            icon: icon,
            confirmButtonColor: '#2675cf',
            iconColor: '#FFF',
            background: 'linear-gradient(255.52deg, #0294AB -173.55%, #0294AB 71.9%, rgba(60, 89, 190, 0.7) 117.57%)'
        });
    };

    const confirm = (
        title = 'are you sure?',
        msg = `You won't be able to revert this!`,
        icon = 'warning',
        onOkCallback = (data = false) => {
            return data;
        }
    ) => {
        Swal.fire({
            title: title,
            text: msg,
            icon: icon,
            showCancelButton: true,
            confirmButtonColor: '#2675cf',
            iconColor: '#FFF',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            background: 'linear-gradient(255.52deg, #0294AB -173.55%, #0294AB 71.9%, rgba(60, 89, 190, 0.7) 117.57%)'
        }).then((result) => {
            if (result.isConfirmed) {
                onOkCallback(true);
            }
        });
    };

    const values = useMemo(
        () => ({
            confirm,
            errror,
            info
        }),
        []
    );

    return <SwalContext.Provider value={values}>{children}</SwalContext.Provider>;
};

export default function useSwal() {
    const context = React.useContext(SwalContext);
    if (context === undefined) {
        throw new Error('useSwal hook must be with a useSwalProvider');
    }
    return context;
}
