/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import Stakes from 'components/Stakes';
import useFirebase from '../hooks/useFirebase';
import Navmenu from 'components/Navmenu';
import { useLocation } from 'react-router-dom';

const Staking = () => {
    const [stakingData, setStakingData] = useState(null);
    const [redirectTo, setRedirectTo] = useState('');
    const firebase = useFirebase();
    const { readStaking, firestore } = firebase;
    const { search } = useLocation();

    const _initStaking = async () => {
        const _stakingData = await readStaking();
        if (_stakingData !== null) {
            setStakingData(_stakingData);
        }
    };

    const onRefreshCallback = () => {
        _initStaking();
    };

    React.useEffect(() => {
        if (firestore !== null) {
            _initStaking();
        }
        const query = new URLSearchParams(search);
        const stakeQuery = query.get('stake');
        if(stakeQuery){
          setRedirectTo(stakeQuery);
        }
    }, [firestore, search]);

    return (
        <div id="full">
            <Navmenu onRefreshCallback={onRefreshCallback} />
            <Container style={{ maxWidth: '1080px' }}>
                <div className="text-center w-100 py-4 h1">Staking Room</div>
                <Row className="g-8 row row-cols-md-2 row-cols-lg-3 row-cols-12">
                    {stakingData !== null && stakingData.length > 0
                        ? stakingData
                              .filter(function (x) {
                                  return x.status === 'publish';
                              })
                              .map((x, i) => (
                                  <div key={i}>
                                      <Stakes
                                          apy={x.APY}
                                          baseErcDecimal={x.baseErcDecimal}
                                          baseContract={x.base_contract}
                                          day={x.day}
                                          maxAmount={x.maxAmount}
                                          maxParticipant={x.maxParticipant}
                                          minAmount={x.minAmount}
                                          name={x.name}
                                          quoteDecimals={x.quoteDecimals}
                                          rewardContract={x.rewardContract}
                                          stakeContract={x.stakeContract}
                                          totalRewards={x.totalRewards}
                                          ercSymbol={x.ercSymbol}
                                          precision={x.precision}
                                          preparationDate={x.preparationDate}
                                          startDate={x.startDate}
                                          stakeDate={x.startDate}
                                          endedDate={x.endedDate}
                                          baseToken={x.baseTokenImage}
                                          rewardToken={x.rewardTokenImage}
                                          redirect={redirectTo}
                                      />
                                  </div>
                              ))
                        : null}
                </Row>
            </Container>
        </div>
    );
};

export default Staking;
